import './App.scss';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import Navbar from './components/Navbar/Navbar';
// import SearchCustomer from './pages/SearchCustomer';
import React, { Suspense, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './constants/routeConfig'
import LoadingComponent from './common/LoadingComponent/LoadingComponent';
import { Button, ListItemText, Menu, MenuItem } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';


function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Suspense fallback={<LoadingComponent />}>
          <Navbar />
          <div className='pt-2 pb-1 d-flex align-items-center'>
            <Breadcrumbs />
            <div className='d-flex align-items-center gap-3 pe-4' >
              <Button startIcon={<ChevronLeftRoundedIcon />} onClick={()=>window.history.back()}> Back </Button>
              <Button style={{ minWidth: '20px', padding: '0px', margin: '0px' }}
                id="add-button"
                aria-controls={open ? 'add-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<StarBorderRoundedIcon />}
              >
                Quicklinks
              </Button>
              <Menu
                id="add-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'add-button',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemText>Link one</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemText>Link two</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemText>Link three</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.Component />}
              />
            ))}
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
