import React from 'react'
import { CircularProgress } from '@mui/material'

function LoadingComponent({ height }) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: height ? height : '200px',
    }}>
      <CircularProgress />
    </div>
  )
}

export default LoadingComponent