import React from 'react';
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import './CommentsComponent.scss';

const CommentsComponent = ({ onCommentsChange, inputData, noteId }) => {

  const commentList = inputData?.map((eachComment) => ({
    userId: '02b',
    comId: eachComment?.id,
    fullName: eachComment?.created_by,
    text: eachComment?.comment,
    avatarUrl: `https://ui-avatars.com/api/?name=${eachComment?.created_by}`,
    replies: []
  }))
  return <>{noteId ? <CommentSection
    currentUser={{
      currentUserId: '01a',
      currentUserImg:
        'https://ui-avatars.com/api/?name=Yagnesh+Devashrayee',
      currentUserFullName: 'Yagnesh Devashrayee'
    }}
    commentData={commentList}
    onSubmitAction={(data) => onCommentsChange(data.text)}
    currentData={(data) => {
      console.log('curent data', data)
    }}
  /> : <>
    <div className='row '>
      <div className="col-12 pt-1 ">
        <p className='mb-0'>Please save a note first to Add Comments</p>
      </div>
    </div>
  </>}</>
}

export default CommentsComponent