import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Chip, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, Stack, Tab, Tabs, Typography } from '@mui/material';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

import tableConfig from '../../components/Table/tableConfig';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITEM_HEIGHT, labelMap } from '../../constants/keyConstants';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

import ListCheckbox from '../CustomCheckbox/ListCheckbox';
import { handleGridColumns, initializeColumnsInGrid, updatePagination } from '../../features/table/tableSlice';
import LoadingComponent from '../../common/LoadingComponent/LoadingComponent';
import Board from '../../features/table/components/Board';
import SelectMenu from '../SelectMenu/SelectMenu';

import './TableWrapper.scss';
import VisibilityToggleMenu from '../VisibilityToggleMenu/VisibilityToggleMenu';


const TableWrapper = (props) => {
  const {
    tableTypeKey,
    apiList,
    handleClick,
    onChange,
    value,
    editAction,
    showSwimLane = false,
    actionHandlers,
    isMainTable = true
  } = props;

  const dispatch = useDispatch()


  const [headersList, setHeaderList] = useState(tableConfig(tableTypeKey))
  const [swimlaneDropdownValue, setSwimLaneDropdownValue] = useState("LifeCycle Stage")
  const [cardVisibilityList, setCardVisibilityList] = useState([])
  const { selectedIds, 
    selectedColumnsInGrid, 
    pagination, 
    isLoading,
    leadDropdown,
    companyOwnerdropDownData
  } = useSelector(state => state.table)

  const [columnList, setColumnList] = useState(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId })))

  const colOptions = tableConfig(tableTypeKey)?.filter(eachHeaderItem => eachHeaderItem?.columnName !== null && eachHeaderItem?.columnName !== undefined)

  const [anchorEl, setAnchorEl] = useState(null);
  const [viewSelectionValue, setViewSelectionValue] = useState(0);

  const handleChange = (newValue) => {
    setViewSelectionValue(newValue);
    setColumnList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStageName === owner?.value) })))
  };

  const open = Boolean(anchorEl);
  const disabled = selectedIds?.length === 0;

  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");


  const handleEditColClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    dispatch(updatePagination(value))
  };

  useEffect(() => {
    if (isMainTable) {
      dispatch(initializeColumnsInGrid({ tableType: tableTypeKey }));
    }
  }, [])

  useEffect(() => {
    if (tableTypeKey || apiList) {
      setHeaderList(tableConfig(tableTypeKey))
    } else {
      setHeaderList(tableConfig(tableTypeKey)?.filter(eachHeaderItem => selectedColumnsInGrid?.includes(eachHeaderItem?.columnName)))
    }
  }, [tableTypeKey, apiList, selectedColumnsInGrid])


  const swimlaneDropdownList = [
    { label: "LifeCycle Stage", value: "LifeCycle Stage" },
    { label: "Company Owner", value: "Company Owner" },
    { label: "Lead Status", value: "Lead Status" }
  ];

  useEffect(() => {
    if (swimlaneDropdownValue === "LifeCycle Stage") {
      setColumnList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStageName === owner?.value) })))
    }
    else if (swimlaneDropdownValue === "Lead Status") { setColumnList(leadDropdown && leadDropdown[1]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStatusName === owner?.value) }))) }
    else { setColumnList(companyOwnerdropDownData?.map((owner) => ({ ...owner, label: owner?.optionShow, value: owner?.employeeId, task: apiList?.filter((eachRow) => eachRow.contactOwnerName === owner?.name) }))) }
  }, [swimlaneDropdownValue, leadDropdown, companyOwnerdropDownData])



  const cardItems = Object.keys(labelMap);

  return (
    <div className="row">
      {isLoading ? <LoadingComponent /> : <>
        <div className="col-12 col-lg-4 d-flex">
          {showSwimLane && <IconButton onClick={() => handleChange(0)} className={viewSelectionValue === 0 ? 'selected-button' : 'unselected-button'} title='Grid View'><GridViewIcon fontSize='small' /> </IconButton>}
          {showSwimLane && <IconButton onClick={() => handleChange(1)} className={viewSelectionValue === 1 ? 'selected-button' : 'unselected-button'} title='Swimlane View'><CalendarViewWeekIcon fontSize='small' /> </IconButton>}
          <p className='mt-3 ml-3 fs-14'>Total : <span className=' fw-bold'>{pagination?.totalRecords || apiList.length}</span></p>
        </div>
        <div className='col-12 col-lg-8 d-flex align-items-end justify-content-end' >
          {viewSelectionValue === 0 && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {apiList?.length > 0 && selectedIds?.length === apiList?.length && <Button label="" variant="text">Email All</Button>}
              <Button variant='text' disabled={disabled}><DeleteOutlinedIcon fontSize='small' />Delete</Button>
              <Button variant='text' disabled={disabled}><EditOutlinedIcon fontSize='small' />Edit</Button>
              <Button variant='text' disabled={disabled}><ExitToAppOutlinedIcon fontSize='small' />Export</Button>
              <Button className=''
                aria-label="more"
                id="select-col-button"
                aria-controls={open ? 'colName' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleEditColClick}
                disabled
              >
                Edit Columns <MoreVertIcon />
              </Button>
              <Menu
                id="colName"
                MenuListProps={{
                  'aria-labelledby': 'select-col-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                {colOptions.map((colOptions) => (
                  <MenuItem key={colOptions.columnKey} >
                    <ListCheckbox
                      onChange={(event) => dispatch(handleGridColumns({
                        isChecked: event.target.checked,
                        selectedColumnName: colOptions?.columnName
                      }))}
                      checked={selectedColumnsInGrid?.includes(colOptions?.columnName)}
                      label={colOptions?.columnName}
                    />
                  </MenuItem>
                ))}
              </Menu>
            </Stack>
          )}
          {viewSelectionValue === 1 && (
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="flex-end">
              <div className='filterSelect'>
                <SelectMenu
                  // selectLabel="Filter "
                  value={swimlaneDropdownValue}
                  options={swimlaneDropdownList}
                  onChangeCallback={(selectedValue) => setSwimLaneDropdownValue(selectedValue)}
                />
              </div>
              <div>
                <VisibilityToggleMenu items={cardItems} setCardVisibilityList={setCardVisibilityList} />
              </div>

            </Stack>
          )}
        </div>
        {/* <div className='col-12 col-md-6 col-lg-3 pt-1'>
          <Tabs className='viewOptionTabs'
            onChange={handleChange}
            value={viewSelectionValue}
            aria-label="Tabs where selection follows focus"
            selectionFollowsFocus
          >
            <Tab icon={<GridViewIcon fontSize='small' />} iconPosition='start' label="Grid View"  {...a11yProps(0)} />
            {showSwimLane && <Tab icon={<CalendarViewWeekIcon fontSize='small' />} iconPosition='start' label="Swimlane View" {...a11yProps(1)} />}
          </Tabs>
        </div>
        <div className='col-12 col-md-6 col-lg-9 mt-md-4 text-lg-end'>
          {gridData?.length > 0 && selectedIds?.length === gridData?.length && <Button label="" variant="outlined">Email All</Button>}

        </div>
        <div className='col-12 pt-3'>
          <CustomTabPanel value={viewSelectionValue} index={0} >
            <div className='tableContainer '>
              <table className="table table-hover ">
                <TableHeader
                  headers={headersList}
                  records={apiList}
                  onChange={onChange}
                />
                <TableBody
                  headers={headersList}
                  records={apiList}
                  handleClick={handleClick}
                  onChange={onChange}
                  value={value}
                  editAction={editAction}
                />
              </table>
              <div className="col-12">
                <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                  <p className='m-0 fs-14'>Showing <span className='fw-bold'>{gridData?.length} / {pagination?.totalRecords}</span> Records</p>
                  <Pagination
                    count={pagination?.count}
                    page={pagination?.page}
                    onChange={handlePageChange}
                    color="primary"
                  />

                </Stack>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={viewSelectionValue} index={1}>
            <Board columnList={columnList} taskList={cardVisibilityList} />
          </CustomTabPanel>
        </div> */}



        <div className='col-12'>
          {viewSelectionValue === 0 && (
            <div className='tableContainer '>
              <table className="table table-hover ">
                <TableHeader
                  headers={headersList}
                  records={apiList}
                  onChange={onChange}
                />
                <TableBody
                  headers={headersList}
                  records={apiList}
                  handleClick={handleClick}
                  onChange={onChange}
                  value={value}
                  editAction={editAction}
                  actionHandlers={actionHandlers}
                />
              </table>
              <div className="col-12">
                <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                  <p className='m-0 fs-14'>Showing <span className='fw-bold'>{apiList?.length} Records of Page {pagination?.page}</span></p>
                  <Pagination
                    count={pagination?.count}
                    page={pagination?.page}
                    onChange={handlePageChange}
                    color="primary"
                  />

                </Stack>
              </div>
            </div>
          )}
          {viewSelectionValue === 1 && (
            <div><Board columnList={columnList} taskList={cardVisibilityList} /></div>
          )}
        </div>
      </>}
    </div>
  );
};
export default TableWrapper;
