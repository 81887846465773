import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function MultiInputTags({ options, label, actionHandler = () => { }, valueList, allowCustom = true, ignoreById = [], required }) {
    // const [value, setValue] = useState(valueList?.map(eachValue => ({ ...eachValue, label: eachValue }))); // TODO test with all appearances
    const [value, setValue] = useState(valueList);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        actionHandler([...newValue]?.map(eachValue => eachValue))
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    MultiInputTags.defaultProps = {
        options: [],
        valueList: [],
        ignoreById: []
      };      
    return (
        <div >
            <Autocomplete
                multiple
                value={value}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={ignoreById?.length > 0 ? options?.filter((list)=>!ignoreById?.includes(list.id)): options}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => {
                    return (
                        <TextField {...params} label={label} variant="standard" fullWidth onBlur={() => {
                            if (inputValue !== "" && allowCustom) {
                                const newOption = { label: inputValue };
                                setValue([...value, newOption]);
                                actionHandler([...value, newOption]?.map(eachValue => eachValue.label))
                                setInputValue('');
                            }
                        }} 
                        required={required}
                        />
                    )
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                blurOnSelect
            />
        </div>
    );
}

