import React from 'react';
import Contact from '../pages/Contact/Contact';
import ContactList from '../pages/Contact/ContactList/ContactList';
import AddContact from '../pages/Contact/AddContact/AddContact';
import EditProducts from '../pages/Masters/Products/EditProducts';
import Services from '../pages/Masters/Services/Services';
import ServicesList from '../pages/Masters/Services/ServicesList/ServicesList';
import EditServices from '../pages/Masters/Services/EditServices';

const Home = React.lazy(() => import('../pages/Home'));
const Placeholder = React.lazy(() => import('../pages/Placeholder'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const SalesProposal = React.lazy(() => import('../pages/SalesProposal/SalesProposal'));
const CreateSalesProposal = React.lazy(() => import('../pages/SalesProposal/creacte/CreateSalesProposal'));
const SalesProposalSummary = React.lazy(() => import('../pages/SalesProposal/Summary/SalesProposalSummary'));

const Estimate = React.lazy(() => import('../pages/Estimate/Estimate'));
const EstimateList = React.lazy(() => import('../pages/Estimate/EstimateList/EstimateList'));
const PreviewEstimate = React.lazy(() => import('../pages/Estimate/PreviewEstimate/PreviewEstimate'));
const CustomizeCreateEstimate = React.lazy(() => import('../pages/Estimate/CustomizeCreateEstimate/CustomizeCreateEstimate'));

const JobList = React.lazy(() => import('../pages/Job/JobList/JobList'));
const EditJob = React.lazy(() => import('../pages/Job/EditJob/EditJob'));
const CreateJob = React.lazy(() => import('../pages/Job/CreateJob/CreateJob'));

const Customer = React.lazy(() => import('../pages/Customer/Customer'));
const AddCustomer = React.lazy(() => import('../pages/Customer/AddCustomer/AddCustomer'));
const CustimerList = React.lazy(() => import('../pages/Customer/CustomerList/CustomerList'));

const Products = React.lazy(() => import('../pages/Masters/Products/Products'));
const ProductList = React.lazy(() => import('../pages/Masters/Products/ProductsList/ProductsList'));

const Deal = React.lazy(() => import('../pages/Masters/Deal/Deal'));
const DealList = React.lazy(() => import('../pages/Masters/Deal/DealList/DealList'));
// const AddDeal = React.lazy(() => import('../pages/Masters/Deal/AddDeal/AddDeal'));

const Projects = React.lazy(() => import('../pages/Worx/Projects/Projects'));
const ProjectsList = React.lazy(() => import('../pages/Worx/Projects/ProjectList/ProjectList'));
const ProjectsView = React.lazy(() => import('../pages/Worx/Projects/ProjectsView/ProjectsView'));

const Task = React.lazy(() => import('../pages/Worx/Tasks/Tasks'));
const TaskList = React.lazy(() => import('../pages/Worx/Tasks/TaskList/TaskList'));
const EditTask = React.lazy(()=> import ('../pages/Worx/Tasks/UpdateTask'))


const AddExpenses = React.lazy(() => import('../pages/Expenses/AddExpenses/AddExpenses'));
const ExpensesList = React.lazy(() => import('../pages/Expenses/ExpensesList/ExpensesList'));

const AddTicket = React.lazy(() => import('../pages/Tickets/AddTickets/AddTickets'));
const TicketList = React.lazy(() => import('../pages/Tickets/TicketList/TicketList'));

const Settings = React.lazy(() => import('../pages/Settings/Settings'));


//NOTE : breadcrumbText property should have same hierarchy as path property to display Custom Text

const routes = [
  { path: '/', name: 'Home', Component: Home, breadcrumbText: '/' },
  { path: "/sales/sales-proposal", name: "SalesProposal", Component: SalesProposal, breadcrumbText: '/Sales WorX/Sales Proposal' },
  { path: "/sales/sales-proposal/create", name: "CreateSalesProposal", Component: CreateSalesProposal, breadcrumbText: '/Sales WorX/Sales Proposal/Create' },
  { path: "/sales/sales-proposal/summary", name: "SalesProposalSummary", Component: SalesProposalSummary, breadcrumbText: '/Sales WorX/Sales Proposal/Summary' },

  { path: "/sales/estimate", name: "Estimate", Component: Estimate, breadcrumbText: '/Sales WorX/Estimates/' },
  { path: "/sales/estimate/list", name: "EstimateList", Component: EstimateList, breadcrumbText: '/Sales WorX/Estimates/List' },
  { path: "/sales/estimate/edit/:id", name: "CreateEstimate", Component: Estimate, breadcrumbText: '/Sales WorX/Estimates/Create' },
  { path: "/sales/estimate/create", name: "CreateEstimate", Component: Estimate, breadcrumbText: '/Sales WorX/Estimates/Create' },
  { path: "/sales/estimate/preview", name: "PreviewEstimate", Component: PreviewEstimate, breadcrumbText: '/Sales WorX/Estimate/Preview' },
  { path: "/sales/estimate/customize", name: "CustomizeCreateEstimate", Component: CustomizeCreateEstimate, breadcrumbText: '/Sales WorX/Estimate/Customize Estimate Form' },

  { path: '/sales/job/Edit', name: 'EditJob', Component: EditJob, breadcrumbText: '/Sales WorX/Job/Edit' },
  { path: '/sales/job/Create', name: 'CreateJob', Component: CreateJob, breadcrumbText: '/Sales WorX/Job/Create' },
  { path: '/sales/job/list', name: 'JobList', Component: JobList, breadcrumbText: '/Sales WorX/Job/List' },


  // { path: '/marketing', name: 'Marketing', Component: Placeholder, breadcrumbText: '/Marketing' },

  { path: '/customer', name: 'Customer', Component: Customer, breadcrumbText: '/Customer' },
  { path: '/customer/add', name: 'Customer', Component: AddCustomer, breadcrumbText: '/Customer/Add' },
  { path: '/customer/edit/:id', name: 'Customer', Component: AddCustomer, breadcrumbText: '/Customer/Add' },
  { path: '/customer/list', name: 'CustimerList', Component: CustimerList, breadcrumbText: '/Customer/List' },

  { path: '/contact', name: 'Contact', Component: Contact, breadcrumbText: '/CRM WorX/Contact' },
  { path: '/contact/add', name: 'Contact', Component: Contact, breadcrumbText: '/CRM WorX/Contact/Add' },
  { path: '/contact/edit/:id', name: 'Contact', Component: AddContact, breadcrumbText: '/CRM WorX/Contact/Edit' },
  { path: '/contact/list', name: 'ContactList', Component: ContactList, breadcrumbText: '/CRM WorX/Contact/List' },


  { path: '/masters/products', name: 'Products', Component: ProductList, breadcrumbText: '/Price Book/Products' },
  { path: '/masters/products/list', name: 'ProductList', Component: ProductList, breadcrumbText: '/Price Book/Products/List' },
  { path: '/masters/products/add', name: 'AddProduct', Component: Products, breadcrumbText: '/Price Book/Products/Add' },
  { path: '/masters/products/edit/:id', name: 'EditProduct', Component: EditProducts, breadcrumbText: '/Price Book/Products/Edit' },

  { path: '/masters/services', name: 'Services', Component: ServicesList, breadcrumbText: '/Price Book/Services' },
  { path: '/masters/services/list', name: 'ServicesList', Component: ServicesList, breadcrumbText: '/Price Book/Services/List' },
  { path: '/masters/services/add', name: 'AddServices', Component: Services, breadcrumbText: '/Price Book/Services/Add' },
  { path: '/masters/services/edit/:id', name: 'EditServices', Component: EditServices, breadcrumbText: '/Price Book/Services/Edit' },

  { path: '/masters/deal', name: 'Deal', Component: Deal, breadcrumbText: '/CRM WorX/Deal' },
  { path: '/masters/deal/list', name: 'DealList', Component: DealList, breadcrumbText: '/CRM WorX/Deal/List' },
  { path: '/masters/deal/add', name: 'AddDeal', Component: Deal, breadcrumbText: '/CRM WorX/Deal/Add' },

  { path: '/masters/ticket/list', name: 'TicketList', Component: TicketList, breadcrumbText: '/CRM WorX/Ticket/List' },
  { path: '/masters/ticket/add', name: 'AddTicket', Component: AddTicket, breadcrumbText: '/CRM WorX/Ticket/Add' },

  { path: '/worx/projects', name: 'Projects', Component: ProjectsList, breadcrumbText: '/Project WorX/ProjectList' },
  { path: '/worx/projects/list', name: 'ProjectsList', Component: ProjectsList, breadcrumbText: '/Project WorX/Project/List' },
  { path: '/worx/projects/add', name: 'AddProject', Component: Projects, breadcrumbText: '/Project WorX/Project/Add' },
  { path: '/worx/projects/view/:id', name: 'ProjectsView', Component: ProjectsView, breadcrumbText: '/Project WorX/Project/View' },
  { path: '/worx/projects/edit/:id', name: 'ProjectsView', Component: Projects, breadcrumbText: '/Project WorX/Project/Edit' },

  { path: '/worx/task', name: 'Task', Component: TaskList, breadcrumbText: '/CRM WorX/Task' },
  { path: '/worx/task/list', name: 'TaskList', Component: TaskList, breadcrumbText: '/CRM WorX/Task/List' },
  { path: '/worx/task/add', name: 'Task', Component: Task, breadcrumbText: '/CRM WorX/Task' },
  { path: '/worx/task/edit/:id', name: 'EditTask', Component: EditTask, breadcrumbText: '/CRM WorX/Task/Edit' },

  { path: '/sales/expenses/add', name: 'AddExpenses', Component: AddExpenses, breadcrumbText: '/Sales WorX/Expenses/Add' },
  { path: '/sales/expenses/list', name: 'ExpensesList', Component: ExpensesList, breadcrumbText: '/Sales WorX/Expenses/List' },



  { path: '/settings', name: 'settings', Component: Settings, breadcrumbText: '/Settings' },



  { path: '*', name: 'NotFound', Component: NotFound, breadcrumbText: '*' },
];

export default routes;
