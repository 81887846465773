import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';


export const __API_BASEURL__ = 'https://api.worxstream.io'


export const TEXT = 'TEXT';
export const LINK = 'LINK';
export const PROFILE = 'PROFILE';
export const NUMBER = 'NUMBER';
export const CHECKBOX = 'CHECKBOX';
export const AMOUNT = 'AMOUNT';
export const ACTION = 'ACTION';
export const LIST = 'LIST';
export const DOCUMENT = 'DOCUMENT';
export const DATE = 'DATE';
export const STATUS = 'STATUS';
export const TOGGLE = 'TOGGLE';
export const DROPDOWN = 'DROPDOWN';
export const MARKUP = 'MARKUP';
export const LONG_TEXT = 'LONG_TEXT';
export const TEMP_TEXT = 'TEMP_TEXT';


export const DELETE_ACTION = 'DELETE_ACTION';
export const EDIT_ACTION = 'EDIT_ACTION';
export const CLONE_ACTION = 'CLONE_ACTION';
export const VIEW_ACTION = 'VIEW_ACTION';
export const MENU_LIST = 'MENU_LIST';

export const ASC = 'asc';
export const DESC = 'desc';
export const EMPTY_VALUE = '--';

// Table types

export const SAMPLE_TABLE = 'SAMPLE_TABLE';
export const ESTIMATE_PREVIEW_GRID = 'ESTIMATE_PREVIEW_GRID';
export const ESTIMATE_LIST = 'ESTIMATE_LIST';
export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const CONTACT_LIST = 'CONTACT_LIST';
export const PRODUCT_LIST = 'PRODUCT_LIST';
export const SERVICE_LIST = 'SERVICE_LIST';
export const INSTA_JOBS_LIST = 'INSTA_JOBS_LIST';
export const SF_SEER1_SEARCH_RESULT = 'SF_SEER1_SEARCH_RESULT';
export const JOB_LIST = 'JOB_LIST';
export const DEAL_LIST = 'DEAL_LIST';
export const PROJECT_LIST = 'PROJECT_LIST';
export const TASK_LIST = 'TASK_LIST';
export const SUBTASK_LIST = 'SUBTASK_LIST';
export const EXPENSES_LIST = 'EXPENSES_LIST';
export const TICKET_LIST = 'TICKET_LIST';
export const MEMBER_LIST = 'MEMBER_LIST';
export const USER_LIST = 'USER_LIST';

//utils

export const ITEM_HEIGHT = 60;

// Page specific objects

//contact page
export const labelMap = {
    // "id": "ID",
    // "email": "Email",
    // "phone": "Phone",
    // "contactId": "Contact ID",
    // "firstName": "First Name",
    // "lastName": "Last Name",
    // "companyName": "Company Name", 
    // "displayName": "Display Name",
    // "phonePrefix": "Phone Prefix",
    "contactOwner": "Owner ID",
    "contactStatus": "Status ID",
    "contactStage": "Stage ID",
    "contactOwnerName": "Owner Name",
    // "contactStatusName": "Status Name",
    "contactStageName": "Stage Name",
};

export const iconMap = {
    "email": <AlternateEmailOutlinedIcon fontSize='small' />,

};

export const unitOfMeasure = [
    {
        "value": "meter",
        "abbreviation": "m",
        "type": "length",
        "label": "Meter"
    },
    {
        "value": "kilogram",
        "abbreviation": "kg",
        "type": "mass",
        "label": "Kilogram"
    },
    {
        "value": "second",
        "abbreviation": "s",
        "type": "time",
        "label": "Second"
    },
    {
        "value": "ampere",
        "abbreviation": "A",
        "type": "electric current",
        "label": "Ampere"
    },
    {
        "value": "kelvin",
        "abbreviation": "K",
        "type": "temperature",
        "label": "Kelvin"
    },
    {
        "value": "mole",
        "abbreviation": "mol",
        "type": "amount of substance",
        "label": "Mole"
    },
    {
        "value": "candela",
        "abbreviation": "cd",
        "type": "luminous intensity",
        "label": "Candela"
    },
    {
        "value": "liter",
        "abbreviation": "L",
        "type": "volume",
        "label": "Liter"
    },
    {
        "value": "gram",
        "abbreviation": "g",
        "type": "mass",
        "label": "Gram"
    },
    {
        "value": "millimeter",
        "abbreviation": "mm",
        "type": "length",
        "label": "Millimeter"
    },
    {
        "value": "centimeter",
        "abbreviation": "cm",
        "type": "length",
        "label": "Centimeter"
    },
    {
        "value": "kilometer",
        "abbreviation": "km",
        "type": "length",
        "label": "Kilometer"
    },
    {
        "value": "mile",
        "abbreviation": "mi",
        "type": "length",
        "label": "Mile"
    },
    {
        "value": "foot",
        "abbreviation": "ft",
        "type": "length",
        "label": "Foot"
    },
    {
        "value": "inch",
        "abbreviation": "in",
        "type": "length",
        "label": "Inch"
    },
    {
        "value": "pound",
        "abbreviation": "lb",
        "type": "mass",
        "label": "Pound"
    },
    {
        "value": "ounce",
        "abbreviation": "oz",
        "type": "mass",
        "label": "Ounce"
    },
    {
        "value": "gallon",
        "abbreviation": "gal",
        "type": "volume",
        "label": "Gallon"
    },
    {
        "value": "quart",
        "abbreviation": "qt",
        "type": "volume",
        "label": "Quart"
    },
    {
        "value": "pint",
        "abbreviation": "pt",
        "type": "volume",
        "label": "Pint"
    },
    {
        "value": "cup",
        "abbreviation": "c",
        "type": "volume",
        "label": "Cup"
    },
    {
        "value": "tablespoon",
        "abbreviation": "tbsp",
        "type": "volume",
        "label": "Tablespoon"
    },
    {
        "value": "teaspoon",
        "abbreviation": "tsp",
        "type": "volume",
        "label": "Teaspoon"
    },
    {
        "value": "joule",
        "abbreviation": "J",
        "type": "energy",
        "label": "Joule"
    },
    {
        "value": "watt",
        "abbreviation": "W",
        "type": "power",
        "label": "Watt"
    },
    {
        "value": "newton",
        "abbreviation": "N",
        "type": "force",
        "label": "Newton"
    },
    {
        "value": "pascal",
        "abbreviation": "Pa",
        "type": "pressure",
        "label": "Pascal"
    },
    {
        "value": "bar",
        "abbreviation": "bar",
        "type": "pressure",
        "label": "Bar"
    },
    {
        "value": "atmosphere",
        "abbreviation": "atm",
        "type": "pressure",
        "label": "Atmosphere"
    },
    {
        "value": "degree Celsius",
        "abbreviation": "°C",
        "type": "temperature",
        "label": "Degree Celsius"
    },
    {
        "value": "degree Fahrenheit",
        "abbreviation": "°F",
        "type": "temperature",
        "label": "Degree Fahrenheit"
    },
]

