import { configureStore } from '@reduxjs/toolkit';
import tableReducer from './../features/table/tableSlice'
import taskReducer from './../features/table/taskSlice'
import pipelineReducer from './../features/table/pipelineSlice'
export const store = configureStore({
    reducer: {
        table : tableReducer,
        task: taskReducer,
        pipeline: pipelineReducer
    },
})