import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import expandCollapseIcon from '../../../assets/images/arrow-blue-circle.svg';
import expandCollapseRightIcon from '../../../assets/images/arrow-blue-circle-right.svg';
import productHomeIcon from '../../../assets/images/img-add-product.png';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from "@mui/material";
import { useLocation } from 'react-router';
import ServicesLeftPanel from "./ServicesLeftPanel/ServicesLeftPanel";
import "./Services.scss";
import { fetchProductMasterDetails, postCreateContact } from '../../../features/table/tableSlice';
import { useDispatch } from 'react-redux';
import Notifications from '../../../components/Notifications/Notifications';
import EditServices from './EditServices';

export default function Services() {
  const [isLeftPanelVisible, setLeftPanelVisibility] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");
  const [showSecondaryButton, setShowSecondaryButton] = useState(false)
  const [productDetails, setProductDetails] = useState({
    "name": "",
    "description": "",
    "type": "service",
    "currency": "usd",
    "costPerItem": 0,
    "salesPrice": 0,
    "qty": 1,
  });
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    color: "success",
  });

  const toggleLeftPanel = () => {
    setLeftPanelVisibility(!isLeftPanelVisible);
  };


  const addProductBtnClick = () => {
    const { name, description, currency, qty, costPerItem, salesPrice, margin, markup, contractCost } = productDetails;
    const requestData = {
      "companyId": company_id,
      "userId": user_id,
      name,
      description,
      "type": 'service',
      currency,
      qty,
      costPerItem,
      salesPrice,
      margin,
      markup,
      // contractCost,
    }
    const response = dispatch(postCreateContact({ endpointURL: `/master/product/create?`, payload: requestData }))
    response?.then(function (res) {
      if (res?.payload?.data) {
        setNotification({
          open: true,
          message: `${res.payload?.data?.message}`,
          color: "success",
        })
        setTimeout(() => {
          if (res.payload?.data?.result?.productId) {
            dispatch(fetchProductMasterDetails(`/master/product/details?company_id=${company_id}&user_id=${user_id}&product_id=${res.payload.data?.result.productId}`))
            navigate(`/masters/services/edit/${res.payload.data.result.productId}`);
          }
        }, 1000)
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went Wrong while adding services',
          color: "error",
        })
      });
  };

  
  const onCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }
  
  return (<>
    {notification?.open && <Notifications {...notification} onClose={onCloseNotification}/>}
    <div className="container-fluid products">
      <div className="d-flex gap-3 position-relative">
        <IconButton aria-label="Collapse" className={`expandCollapseBtn ${isLeftPanelVisible ? ' ' : 'moveLeft'}`} onClick={toggleLeftPanel}>
          <img src={`${isLeftPanelVisible ? expandCollapseIcon : expandCollapseRightIcon}`} alt='' />
        </IconButton>
        <div className={`leftPanel ${isLeftPanelVisible ? 'visiblePanel ' : 'hiddenPanel'}`}>
          <div className=" white-container ">
            <ServicesLeftPanel
              productDetails={productDetails}
              setProductDetails={setProductDetails}
              addProductBtnClick={addProductBtnClick}
              pageType="ADD"
            />
          </div>
        </div>
        <div className={`rightPanel ${isLeftPanelVisible ? ' ' : 'rp100'}`}>
          {!location?.pathname?.includes('edit') ? <div >
            <div className=" white-container ">
              <div className="row p-0 p-md-5">
                <div className="col-12 p-0 p-md-5">
                  <img src={productHomeIcon} alt='' />
                  <h4 className='ps-5'>Add a new service by entering essential details in the left panel.</h4>
                  <p className='ps-5'>Once the initial service is added, you can further input additional information such as description, inventory, location, shipping, and more.</p>
                </div>

              </div>
            </div>
          </div> : <EditServices />}
        </div>
      </div>
    </div>
  </>
  );
}
