import React from "react";
import styled from "styled-components";
import Card from "./Card";
import "./scroll.scss";
import { Droppable } from "react-beautiful-dnd";

const Container = styled.div`
    background-color: #f4f5f7;
    border-radius: 2px;
    // width: 400px;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1px solid #ffffff;
`;

const Title = styled.p`
    padding: 5px;
    width: 270px;
    text-align: center;
    // font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TaskList = styled.div`
    padding: 3px;
    transistion: background-color 0.2s ease;
    background-color: #f4f5f7;
    flex-grow: 1;
    min-height: 100px;
`;

export default function Column({ title, tasks, id, taskList }) {
    return (
        <Container className="column">
            <Title
                style={{
                    backgroundColor: "#e8f1fc",
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                }}
            >
                {title}
            </Title>
            <Droppable droppableId={id}>
                {(provided, snapshot) => (
                    <TaskList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                    >
                        {tasks.map((task, index) => (
                            <Card key={index} index={index} task={task} taskList={taskList}/>
                        ))}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        </Container>
    );
}