import React, { useState } from "react";
import { FormControl, FormControlLabel, FormLabel, Switch, TextField, Checkbox } from "@mui/material";

import AutocompleteSelectWithAdd from "../../../../components/AutocompleteSelectWithAdd/AutocompleteSelectWithAdd";
import AutocompleteSelect from '../../../../components/AutocompleteSelect/AutocompleteSelect';
import MultiInputTags from '../../../../components/MultiInputTags/MultiInputTags';
import { parseToNumber } from '../../../../common/utils';
import SelectMenu from '../../../../components/SelectMenu/SelectMenu';

const ProductCategory = ({
  // productMasterDetails,
  categoryList,
  productDetails,
  setProductDetails,
  subCategoryList,
  classOfProductList,
  measureOfUnitList,
  addItemCallback }) => {

  const productCategoryOptions = categoryList?.map((eachData) => ({ ...eachData, label: eachData?.category, value: eachData?.category }))
  const { categoryId, subCategoryId, productNumber, autoPo } = productDetails;
  const [automaticPurchaseOrder, setAutomaticPurchaseOrder] = useState(parseToNumber(productDetails?.autoPo));
  const [chargeTax, setChargeTax] = useState(parseToNumber(productDetails?.taxable));
  const handleAutomaticPurchaseOrder = (event) => {
    setAutomaticPurchaseOrder(event.target.checked);
  };

  const handleChargeTax = (event) => {
    setChargeTax(event.target.checked);
  };

  const dropdownLabelToShow = (listOptions, catId) => {
    return listOptions?.find(listItem => listItem.id === catId)?.label || null;
  }

  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
        <AutocompleteSelectWithAdd
          options={productCategoryOptions || []}
          label="Category"
          value={dropdownLabelToShow(productCategoryOptions, productDetails?.categoryId ? parseToNumber(productDetails?.categoryId) : parseToNumber(categoryId))}
          onChangeCallback={(selectedValue) => setProductDetails({ ...productDetails, categoryId: selectedValue?.id })}
          addItemCallback={addItemCallback}
          resetId="categoryId"
        />

      </div>
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
        <AutocompleteSelectWithAdd
          options={subCategoryList || []}
          label="Product Sub Category"
          value={dropdownLabelToShow(subCategoryList, productDetails?.subCategoryId ? parseToNumber(productDetails?.subCategoryId) : parseToNumber(subCategoryId))}
          onChangeCallback={(selectedValue) => setProductDetails({ ...productDetails, subCategoryId: selectedValue?.id })}
          addItemCallback={addItemCallback}
          resetId="subCategoryId"
          disabled={!productDetails?.categoryId}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <TextField label="Product Number" variant="standard" fullWidth
          defaultValue={productDetails?.productNumber}
          onBlur={(event) => {
            setProductDetails({ ...productDetails, productNumber: event.target.value })
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <TextField label="Package Model Number" variant="standard" fullWidth
          defaultValue={productDetails?.modelNumber}
          onBlur={(event) => {
            setProductDetails({ ...productDetails, modelNumber: event.target.value })
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        {/* <AutocompleteSelectWithAdd
          options={measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.map((eachValue => ({ label: eachValue.description, ...eachValue }))) || []}
          value={measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.find(listItem => listItem.optionValueId === productDetails?.unitOfMeasureId)?.description ||
            measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.find(listItem => listItem.description === productDetails?.unitOfMeasure)?.description ||
            null}
            onChangeCallback={(selectedValue) => {
            setProductDetails({ ...productDetails, unitOfMeasure: measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.find(listItem => listItem.optionValueId === selectedValue?.optionValueId)?.description || measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.find(listItem => listItem.description === selectedValue?.description)?.description, unitOfMeasureId: selectedValue?.optionValueId })
          }
          }
          addItemCallback={addItemCallback}
          label="Unit of Measure"
          callbackType="CUSTOM_MOU"
          resetId="unitOfMeasure"
        /> */}
        <SelectMenu
          selectLabel="Unit of Measure"
          options={measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.map((eachValue => ({ ...eachValue, label: eachValue.description, value: eachValue.description }))) || []}
          value={productDetails?.unitOfMeasure}
          onChangeCallback={(selectedValue) => {
            setProductDetails({ ...productDetails, unitOfMeasure: measureOfUnitList && measureOfUnitList[0] && measureOfUnitList[0]?.values?.find(listItem => listItem.value == selectedValue)?.description })
          }
          }
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <AutocompleteSelectWithAdd
          options={classOfProductList && classOfProductList[0] && classOfProductList[0]?.values?.map((eachValue => ({ label: eachValue.description, ...eachValue }))) || []}
          value={classOfProductList && classOfProductList[0] && classOfProductList[0]?.values?.find(listItem => listItem.optionValueId === productDetails?.classOfProductId)?.description ||
            classOfProductList && classOfProductList[0] && classOfProductList[0]?.values?.find(listItem => listItem.description === productDetails?.classOfProduct)?.description ||
            null}
          onChangeCallback={(selectedValue) => {
            setProductDetails({ ...productDetails, classOfProduct: selectedValue?.label, classOfProductId: selectedValue?.optionValueId })
          }
          }
          // value={productDetails?.classOfProduct}
          addItemCallback={addItemCallback}
          label="Class of Product"
          callbackType="CUSTOM_COP"
          resetId="classOfProduct"
        />
      </div>
      <div className="col-12 col-md-6 col-lg-8 col-xl-6">
        <MultiInputTags
          actionHandler={(addedValue) => {
            setProductDetails({ ...productDetails, tags: addedValue?.map((eachVal) => eachVal.label) })
          }}
          options={productDetails?.tags && productDetails?.tags?.map((eachVal) => ({ label: eachVal })) || []}
          valueList={productDetails?.tags?.map((list) => ({ ...list, label: list }))}
          // ignoreById={projectDetails?.members?.map(list => list.id)}  - TODO add ignoreById props
          label="Tags"
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        {/* <SelectMenu selectLabel="Assign Vendor" /> */}
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3  pt-3">
        <FormControl>
          <FormLabel id="automaticPurchaseOrder" className="fs-12">Automatic Purchase Order</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={automaticPurchaseOrder}
                onChange={(event) => {
                  handleAutomaticPurchaseOrder(event)
                  setProductDetails({ ...productDetails, autoPo: event.target.checked })
                }}
              />
            }
            label={automaticPurchaseOrder ? "Yes" : "No"}
          />
        </FormControl>

      </div>
      <div className="col-12 col-md-4 col-lg-4 col-xl-3  mt-4">
        {/* <FormControlLabel control={<Switch checked={productDetails?.taxable} />} label="Charge Tax" /> */}
        <FormControl>
          {/* <FormLabel id="automaticPurchaseOrder" className="fs-12">Automatic Purchase Order</FormLabel> */}
          <FormControlLabel
            control={
              <Switch
                checked={chargeTax}
                onChange={(event) => {
                  handleChargeTax(event)
                  setProductDetails({ ...productDetails, taxable: event.target.checked })
                }}
              />
            }
            label="Charge Tax"
          />
        </FormControl>
      </div>
    </div>
  );
}

export default ProductCategory;
