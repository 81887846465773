import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const filter = createFilterOptions();

export default function AutocompleteSelectWithAdd({ value: defaultValue, options = [], label, onChangeCallback, addItemCallback = () => { }, disabled = false, callbackType,
  resetId, toolTipTitle, required }) {
  const [value, setValue] = React.useState(defaultValue);
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    label: '',
  });
  const handleClose = () => {
    setDialogValue({
      label: '',
    });
    toggleOpen(false);
  };

  const handleSubmit = () => {
    addItemCallback(dialogValue?.label, callbackType, resetId)
    toggleOpen(false);
  };

  React.useEffect(() => {
    if (open == true) {
      setValue(null)
    }
  }, [open])

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <React.Fragment>
      <Autocomplete
        disableClearable
        disabled={disabled}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                label: newValue,
              });
            });
            setValue(newValue)
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              label: newValue.inputValue,
            });
            setValue(newValue)
          } else {
            onChangeCallback(newValue)
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          return filter(options, params);
        }}
        id={label}
        options={options.length === 0 ? [{}] : options}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option, rowData) => {
          const { key, ...optionProps } = props;
          return (
            rowData?.index === 0 ? <>{
              option?.label ? <>
                <Tooltip title={toolTipTitle || label} arrow placement='top'><Button variant='text' fullWidth startIcon={<AddIcon />} className='justify-center' onClick={() => toggleOpen(true)}>ADD {label}</Button></Tooltip>
                <li key={key} {...optionProps}>
                  {option?.label}
                </li>
              </> : <Tooltip title={toolTipTitle || label} arrow placement='top'><Button variant='text' fullWidth startIcon={<AddIcon />} className='justify-center' onClick={() => toggleOpen(true)}>ADD {label}</Button></Tooltip>}
            </>
              : <li key={key} {...optionProps}>
                {option?.label}
              </li>
          );
        }}
        freeSolo
        renderInput={(params) => <TextField variant='standard' {...params} label={label} fullWidth required={required} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Add {label}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.label}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                label: event.target.value,
              })
            }
            label="Title"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
