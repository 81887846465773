import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { labelMap } from '../../constants/keyConstants';

const VisibilityToggleMenu = ({ items, setCardVisibilityList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openSwimlaneViewFilter = Boolean(anchorEl);
  const [visibility, setVisibility] = useState(
    items.reduce((acc, item) => ({ ...acc, [item]: true }), {})
  );
  useEffect(() => { setCardVisibilityList(visibility) }, [visibility])
  const handleSwimlaneViewFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSwimlaneViewFilterClose = () => {
    setAnchorEl(null);
  };

  const toggleVisibility = (item) => {
    setVisibility((prev) => ({
      ...prev,
      [item]: !prev[item],
    }));
  };

  return (
    <div>
      <Button
        id="filter-button"
        aria-controls={openSwimlaneViewFilter ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openSwimlaneViewFilter ? 'true' : undefined}
        onClick={handleSwimlaneViewFilterClick}
      >
        <RemoveRedEyeOutlinedIcon />
      </Button>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={openSwimlaneViewFilter}
        onClose={handleSwimlaneViewFilterClose}
        MenuListProps={{ 'aria-labelledby': 'filter-button' }}
      >
        {Object.keys(visibility).map((key) => (
          <MenuItem key={key} onClick={() => toggleVisibility(key)}>
            <ListItemIcon>
              {visibility[key] ? (
                <RemoveRedEyeOutlinedIcon fontSize="small" />
              ) : (
                <VisibilityOffOutlinedIcon fontSize="small" />
              )}
            </ListItemIcon>
            {/* <ListItemText>{key.charAt(0).toUpperCase() + key.slice(1)}</ListItemText> */}
            <ListItemText>{labelMap[key]}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default VisibilityToggleMenu;
