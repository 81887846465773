import React, { useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import EastIcon from "@mui/icons-material/East";
import CloseIcon from '@mui/icons-material/Close';
import './Search.scss'
import { TextField } from '@mui/material';

const Search = ({ searchText,
    onClick = () => { },
    onChange = () => { },
    onClearAll = () => { },
    placeholder = "Search Here"
}) => {


    return (
        <div className="search-box">
            <SearchIcon className="position-absolute " />
            <TextField
                id="input-with-sx"
                label={placeholder}
                variant="standard"
                onChange={(event) => onChange(event)}
                value={searchText}
            />
            <CloseIcon
                className='searchClearBtn'
                onClick={() => onClearAll()}
            />
            {/* <EastIcon className='goSearch' onClick={(event) => onClick(searchText)} /> */}
        </div>
    );
}

export default Search;