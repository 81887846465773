import React, { useEffect, useState } from 'react';
import ReactSummernote from 'react-summernote';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery/dist/jquery';
import $ from 'jquery';
import 'react-summernote/dist/react-summernote.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './TextEditor.scss'

window.$ = window.jQuery = $; // Ensure jQuery is available globally

const TextEditor = ({ input = '', onChangeCallback = () => { }, height = 250 }) => {
    const [content, setContent] = useState('');

    const onChange = (editorData) => {
        if (editorData?.target?.innerHTML) {
            onChangeCallback(editorData?.target?.innerHTML)
        } else {
            onChangeCallback(editorData)
        }
    };

    const onImageUpload = (files) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            ReactSummernote.insertImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
        onChangeCallback(content?.target?.innerHTML)
    };

    useEffect(() => {
        setContent(input)
    }, [input])

    useEffect(() => {// The empty array ensures this effect runs once on mount and once on unmount
        return () => {
            onChangeCallback(content?.target?.innerHTML)
        };
    }, []);

    return (
        <div className="text-editor">
            <ReactSummernote
                value={content}
                options={{
                    height, 
                    dialogsInBody: true,
                    toolbar: [
                        ['style', ['style']],
                        ['font', ['bold', 'italic', 'underline', 'clear']],
                        ['fontname', ['fontname']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['table', ['table']],
                        // ['insert', ['link', 'picture', 'video']],
                        ['color', ['color']],
                    ],
                    fontNames: ['Arial', 'Courier New', 'Times New Roman'], // Font name options
                    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '24', '28', '36', '48', '64'], // Font size options
                }}
                onBlur={onChange}
                onChange={onChange}
                onImageUpload={onImageUpload}
            />
        </div>
    );
}

export default TextEditor;
