import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddProduct from "./AddProducts/AddProducts";

import expandCollapseIcon from '../../../assets/images/arrow-blue-circle.svg';
import expandCollapseRightIcon from '../../../assets/images/arrow-blue-circle-right.svg';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from "@mui/material";
import { useLocation } from 'react-router';
import ProductsLeftPanel from "./ProductsLeftPanel/ProductsLeftPanel";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductMasterDetails, postCreateContact } from '../../../features/table/tableSlice';
import LoadingComponent from '../../../common/LoadingComponent/LoadingComponent';
import "./Products.scss";
import { isEmpty } from 'lodash';
import Notifications from '../../../components/Notifications/Notifications';

export default function EditProducts() {
  const [isLeftPanelVisible, setLeftPanelVisibility] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productMasterDetails, productMasterImageDetails } = useSelector(state => state.table)

  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");
  const location = useLocation();
  const paths = location.pathname.split('/');
  const productIdByPage = paths[paths.length - 1];
  const toggleLeftPanel = () => {
    setLeftPanelVisibility(!isLeftPanelVisible);
  };

  const [productDetails, setProductDetails] = useState();
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    color: "success",
  });

  const incomeAmountOptions = [
    { label: 'Income amount', value: 1 },
    { label: 'Amount two', value: 2 },
    { label: 'Amount three', value: 3 },
    { label: 'Amount four', value: 4 },
    { label: 'Amount five', value: 5 },
  ];

  const handleAmountSelect = (event, value) => {
    console.log('Selected value:', value);
  };
  const addProductBtnClick = (id) => {
    
    const { name, description, type, currency, qty, costPerItem, salesPrice, margin, markup } = productDetails;
    const requestData = {
      "companyId": company_id,
      "userId": user_id,
      name,
      description,
      type,
      currency,
      qty,
      costPerItem,
      salesPrice,
      margin, 
      markup,
      // contractCost,
      ...productDetails,
      id,
      productId: id, 
      description : productMasterDetails?.description
    }
    const response = dispatch(postCreateContact({ endpointURL: `/master/product/update?`, payload: requestData }))
    response.then(function (res) {
      if (res?.payload?.data) {
        setNotification({
          open: true,
          message: `${res.payload?.data?.message}`,
          color: "success",
        })
        if (res.payload?.data?.result?.productId) {
          dispatch(fetchProductMasterDetails(`/master/product/details?company_id=${company_id}&user_id=${user_id}&product_id=${res.payload.data?.result.productId}&type=product`))
          navigate(`/masters/products/edit/${res.payload.data.result.productId}`);
        }
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went Wrong while adding product',
          color: "error",
        })
      });
  };

  useEffect(() => {
    dispatch(fetchProductMasterDetails(`/master/product/details?company_id=${company_id}&user_id=${user_id}&product_id=${productIdByPage}&type=product`))
  }, [])

  const onClose = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }

  return (
    <div className="container-fluid products">
      {notification?.open && <Notifications {...notification} onClose={onClose}/>}
      <div className="d-flex gap-3 position-relative">
        <IconButton aria-label="Collapse" className={`expandCollapseBtn ${isLeftPanelVisible ? ' ' : 'moveLeft'}`} onClick={toggleLeftPanel}>
          <img src={`${isLeftPanelVisible ? expandCollapseIcon : expandCollapseRightIcon}`} alt='' />
        </IconButton>
        <div className={`leftPanel ${isLeftPanelVisible ? 'visiblePanel ' : 'hiddenPanel'}`}>
          <div className=" white-container ">
            {productMasterDetails ? <ProductsLeftPanel
              incomeAmountOptions={incomeAmountOptions}
              handleAmountSelect={handleAmountSelect}
              addProductBtnClick={addProductBtnClick}
              productMasterDetails={productMasterDetails}
              productDetails={productDetails}
              setProductDetails={setProductDetails}
            /> : <LoadingComponent />}
          </div>
        </div>
        <div className={`rightPanel ${isLeftPanelVisible ? ' ' : 'rp100'}`}>
          {isEmpty(productMasterDetails) ? <div >
            <LoadingComponent />
          </div> :
            <AddProduct
              addProductBtnClick={addProductBtnClick}
              setProductDetails={setProductDetails}
              productDetails={productDetails}
              productMasterDetails={productMasterDetails}
            />}
        </div>
      </div>


    </div>
  );
}
