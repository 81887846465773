import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Stack, TextField } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { addDecimalPadding, calculateMargin, calculateMarginPrice, calculateMarkupPrice } from '../../../../common/utils';
import { Markup } from 'interweave';

function ServicesLeftPanel(props) {
  const {
    addProductBtnClick,
    productMasterDetails = {},
    productDetails = {},
    setProductDetails = () => { },
    pageType
  } = props;


  const {
    id,
    name,
    description,
    type,
    currency,
    qty,
    margin,
    markup,
    tags,
    weight,
    imageUrl,
    costPerItem,
    contractCost,
    salesPrice,
    incomeAccountId,
    expenseAccountId,
    quickbookItemId,
    quickbookUpdatedAt,
    vendorId,
    categoryId,
    subCategoryId,
    activeStatus,
    productNumber,
    modelNumber,
    unitOfMeasure,
    classOfProduct,
    autoPo,
    availableLocations,
    isPhysicalProduct,
    weightUnit,
    countryOfOrigin,
    systemCode,
    createDate,
  } = productDetails;
  const [marginValue, setMargin] = useState(productMasterDetails?.margin);
  const [markupValue, setMarkup] = useState(productMasterDetails?.markup);
  const [salesPriceValue, setSalesPrice] = useState(productMasterDetails?.salesPrice);
  const [costPerItemValue, setCostPerItemValue] = useState(productMasterDetails?.costPerItem);
  const [error, setError] = useState({})


  useEffect(() => {
    if (pageType !== 'ADD')
      setProductDetails({ ...productMasterDetails })
  }, [productMasterDetails])

  const navigate = useNavigate();
  const handleSubmit = e => {
    e.preventDefault();
    if (!e.target.checkValidity()) {
      alert("Please check the Required Fields marked in (*)...");
    } else {
      addProductBtnClick()
    }
  };
  
  useEffect(() => {
    setProductDetails((prevValue) => ({
      ...prevValue,
      salesPrice: parseInt(salesPriceValue),
      costPerItem: parseInt(costPerItemValue),
      markup: parseInt(markupValue),
      margin: parseInt(marginValue),
    }))
  }, [salesPriceValue, costPerItemValue, markupValue, marginValue])


  return (<>
    <Box component="form" onSubmit={handleSubmit} noValidate>
      {<div className=" leftContent">
        <div className="row">
          <div className="col-12">
            <span className="lineTitle font-medium mb-0 ">{!isEmpty(productMasterDetails) ? <span>Edit</span> : <span>Add</span>} Service</span>
            {!isEmpty(productMasterDetails) && <IconButton title='Service' >
              <EditOutlinedIcon fontSize='small' />
            </IconButton>}
          </div>
          <div className="col-12 col-md-6">
            <TextField label="Service Name/Number"
              name='name'
              variant="standard" fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={productMasterDetails?.name}
              onChange={(event) => {
                if (!event.target.validity.valueMissing) {
                  setError({ ...error, name: false });
                } else {
                  setError({ ...error, name: true });
                }
                setProductDetails({ ...productDetails, name: event.target.value })
              }}
              required
              error={error?.name}
              helperText={
                error?.name ? "Please enter Service Name" : ""
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField label="Service Unit" variant="standard" fullWidth
              type='number'
              InputLabelProps={{ shrink: true }}
              defaultValue={productMasterDetails?.qty}
              name='qty'
              // InputProps={{
              //   endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
              // }}
              onChange={(event) => {
                if (!event.target.validity.valueMissing) {
                  setError({ ...error, qty: false });
                } else {
                  setError({ ...error, qty: true });
                }
                setProductDetails({ ...productDetails, qty: event.target.value })
              }}
              required
              error={error?.qty}
              helperText={
                error?.qty ? "Please enter Quantity" : ""
              }
            />
            {/* <FormControl sx={{ my: 2 }} variant="standard">
              <InputLabel htmlFor="qty">Product Unit</InputLabel>
              <Input
                type='number'
                defaultValue={productMasterDetails?.qty}
                id="qty"
                onChange={(event) => {
                  setProductDetails({ ...productDetails, qty: event.target.value })
                }}
                required
              />
            </FormControl> */}
          </div>
          <div className="col-12 pt-4">
            <p className="lineTitle font-medium mb-0">Pricing</p>
          </div>
          <div className="col-12 col-md-6">
            <TextField label="Service Sales Price"
              name='salesPrice' variant="standard" fullWidth
              InputLabelProps={{ shrink: true }} value={salesPriceValue}
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onBlur={(event) => {
                setSalesPrice(addDecimalPadding(event.target.value))
                setProductDetails({ ...productDetails, salesPrice: addDecimalPadding(event.target.value) })
              }
              }
              onChange={(event) => {
                if (!event.target.validity.valueMissing) {
                  setError({ ...error, salesPrice: false });
                } else {
                  setError({ ...error, salesPrice: true });
                }
                setSalesPrice(event.target.value)
                setMargin(calculateMargin(costPerItemValue, event.target.value))
              }}
              required
              error={error?.salesPrice}
              helperText={
                error?.salesPrice ? "Please enter Sales Price" : ""
              }
            />
            {/* <FormControl sx={{ my: 2 }} variant="standard">
            <InputLabel htmlFor="salesPrice">Product Sales Price</InputLabel>
            <TextField
              type='number'
              defaultValue={salesPriceValue}
              id="salesPrice"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              onChange={(event) => {
                setMargin(calculateMargin(productMasterDetails?.costPerItem, event.target.value))
                setProductDetails({ ...productDetails, salesPrice: event.target.value })
              }}
            />
          </FormControl> */}
          </div>
          <div className="col-12 col-md-6">
            <TextField label="Cost per Item" name="costPerItem" variant="standard" fullWidth
              InputLabelProps={{ shrink: true }} value={costPerItemValue}
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onBlur={(event) => {
                setProductDetails({ ...productDetails, costPerItem: addDecimalPadding(event.target.value) })
              }
              }
              onChange={(event) => {
                // if (!event.target.validity.valueMissing) {
                //   setError({ ...error, costPerItem: false });
                // } else {
                //   setError({ ...error, costPerItem: true });
                // }
                setCostPerItemValue(event.target.value)
                setMargin(calculateMargin(event.target.value, salesPriceValue))
                // setSalesPrice(calculateMarginPrice(event.target.value, marginValue))
              }}
              // required
              // error={error?.costPerItem}
              // helperText={
              //   error?.costPerItem ? "Please enter Cost Price" : ""
              // }
            />
            {/* <FormControl sx={{ my: 2 }} variant="standard">
            <InputLabel htmlFor="costPerItem">Cost per Item</InputLabel>
            <TextField
              type='number'
              id="costPerItem"
              defaultValue={productMasterDetails?.costPerItem}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              onChange={(event) => {
                setProductDetails({ ...productDetails, costPerItem: event.target.value })
              }}
            />
          </FormControl> */}
          </div>
          <div className="col-12 col-md-6">
            <TextField label="Margin(%)" name="margin" variant="standard" fullWidth
              InputLabelProps={{ shrink: true }} value={marginValue}
              type='number'
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(event) => {
                setMargin(event.target.value)
                setMarkup(0)
                setSalesPrice(calculateMarginPrice(costPerItemValue, event.target.value))
              }}
              disabled={costPerItemValue <= 0}
            />
            {/* <FormControl sx={{ my: 2 }} variant="standard">
            <InputLabel htmlFor="Margin">Margin(%)</InputLabel>
            <TextField
              type='number'
              defaultValue={marginValue}
              onChange={(event) => {
                setMargin(event.target.value)
                setMarkup(0)
                setSalesPrice(isNaN(calculateMarginPrice(productMasterDetails?.costPerItem, event.target.value)) ? salesPriceValue : calculateMarginPrice(productMasterDetails?.costPerItem, event.target.value))
                setProductDetails({ ...productDetails, margin: marginValue })
              }}
            />
          </FormControl> */}
          </div>
          <div className="col-12 col-md-6">
            <TextField label="Markup(%)" name="markup" variant="standard" fullWidth
              InputLabelProps={{ shrink: true }} value={markupValue}
              type='number'
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(event) => {
                setMarkup(event.target.value)
                setMargin(0)
                setSalesPrice(calculateMarkupPrice(parseInt(costPerItemValue), parseInt(event.target.value)))
              }}
              disabled={costPerItemValue <= 0}
            />
            {/* <FormControl sx={{ my: 2 }} variant="standard">
            <InputLabel htmlFor="markup">Markup(%)</InputLabel>
            <TextField
              type='number'
              defaultValue={markupValue}
              onChange={(event) => {
                console.log("event.target.value",event.target.value)
                setMarkup(event.target.value)
                setMargin(0)
                setSalesPrice(isNaN(calculateMarkupPrice(productMasterDetails?.costPerItem, event.target.value)) ? salesPriceValue : calculateMarkupPrice(productMasterDetails?.costPerItem, event.target.value))
                setProductDetails({ ...productDetails, markup: markupValue })
              }}
            />
          </FormControl> */}
          </div>
          {/* <div className="col-12">
            <InputLabel sx={{ mt: 2 }} htmlFor="Description">Description</InputLabel>
            <FormControl sx={{ my: 2 }} variant="standard">
              <Markup content={productMasterDetails?.description} />
            </FormControl> */}
          {/* <TextField
            fullWidth
            id="filled-multiline-static"
            label="Description" name="description"
            multiline
            rows={4}
            defaultValue={productMasterDetails?.description}
            variant="filled"
            disabled
          /> */}
          {/* </div> */}
          {/* <div className="col-12 pt-3">
          <Button variant="outlined" >Upload photo</Button>
        </div> */}
        </div>
        {!productMasterDetails?.id && <Stack direction="row" spacing={1} className="pt-3">

          {/* <Button variant="contained">Save & add another</Button> */}
          <Button variant="outlined" onClick={() => navigate('/masters/services/list')}>Cancel</Button>
          <Button type="submit" variant="contained"
          // onClick={() => addProductBtnClick()}
          >
            Add
          </Button>
        </Stack>}
      </div>}
    </Box></>
  );
}

export default ServicesLeftPanel;
