import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { __API_BASEURL__ } from '../../constants/keyConstants';
import { getRequest } from '../../api/axiosConfig';

const initialState = {
    tasks: [],
    selectedTasks:null,
    status: 'idle',
    error: null,
};

export const fetchTaskById = createAsyncThunk('task/fetchTaskById', async (endpointURL) => {

    const response = await getRequest(endpointURL);
    return response.data.result;
    }
    
);

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setSelectTask: (state, action) => {
            state.selectedTasks = action.payload;
        },
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchTaskById.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(fetchTaskById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.tasks = action.payload;
        });
        builder.addCase(fetchTaskById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});

export const { setSelectTask } = taskSlice.actions;

export default taskSlice.reducer;