import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Avatar } from "antd";
import { deepOrange } from "@mui/material/colors";
import EmailIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import StatusIcon from '@mui/icons-material/AutoModeOutlined';

import { iconMap, labelMap } from '../../../constants/keyConstants';

import './Card.scss';

const Container = styled.div`
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
  color: #000;
  margin-bottom: 16px;
  min-height: 120px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${(props) => bgcolorChange(props)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #666666;
`;

const TextContent = styled.div``;

const Icons = styled.div`
  display: flex;
  justify-content: end;
  padding: 2px;
`;
function bgcolorChange(props) {
  return props.isDragging
    ? "lightgreen"
    : props.isDraggable
      ? props.isBacklog
        ? "#F2D7D5"
        : "#DCDCDC"
      : props.isBacklog
        ? "#F2D7D5"
        : "#FFFFFF";
}

function getStatusBgColor(contactStatusName) {
  switch (contactStatusName) {
    case "In Progress":
      return "#2ECB70"; // Green
    case "UnQualified":
      return "#FF6347"; // Red
    case "Open":
      return "#FFD700"; // Yellow
    case "New":
      return "#853293"; // Purple
    case "Open Deal":
      return "#dfa905"; // yellow
    case "Connected":
      return "#008538"; // gr
    default:
      return "#DCDCDC"; // Default gray
  }
}

export default function Card({ task, index, taskList }) {
  console.log("contactStatusName:", task.contactStatusName);
  const allKeys = Object.keys(task);
  
  return (
    <Draggable draggableId={`${task.id}`} key={task.id} index={task.id} >
      {(provided, snapshot) => (
        <Container 
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <Icons className="position-relative">
            <div className="position-absolute">
              <Avatar
                title={task?.firstName + " " + task.lastName}
                alt={task?.firstName}
                style={{
                  backgroundColor: "#7c95ad",
                  letterSpacing: "-1px",
                  fontSize: "11px",
                  height: "28px",
                  width: "28px",
                }}
              >
                {task?.firstName?.split("")[0]?.toUpperCase()}{" "}
                {task?.lastName?.split("")[0]?.toUpperCase()}
              </Avatar>
            </div>
          </Icons>
          <div style={{ display: "flex", justifyContent: "start", padding: 2 }}>
            <span>
              <small>
                #{task.id}
                {"  "}
              </small>
            </span>
          </div>
          <div
          // style={{ display: "flex", justifyContent: "center", padding: 2 }}
          >
            <TextContent style={{color: '#1E90FF'}}>
              <b>{task.displayName}</b>
            </TextContent>
            <TextContent className="pt-1">
               <EmailIcon style={{fontSize: '16px'}} /> {task.email}
            </TextContent>
            <TextContent className="pt-1">
               <PhoneIphoneOutlinedIcon style={{fontSize: '16px'}}  /> {task.phone}
            </TextContent>
            <TextContent className="text-white d-flex justify-content-end">
               <span className="statusBg" style={{ backgroundColor: getStatusBgColor(task.contactStatusName) }}> {task.contactStatusName} </span>
            </TextContent>
            <hr />
            <span style={{ display: "flex", justifyContent: "start" }}>
              {task.firstName} {task.lastName}
            </span>
            
            {/* <span className="text-muted fs-14">{task.email}</span> */}
            {Object.keys(taskList)?.map((eachtask, index) => {
              return taskList[eachtask] ? <>
                {/* <span className="fs-14">{iconMap[eachtask]} : {`${task[eachtask]}`}</span> */}
                <span className="fs-14">{`${labelMap[eachtask]} : ${task[eachtask]}`}</span>
                {/* {(allKeys.length - 1) !== index && <hr className="" />} */}
                <br />
              </> : null
            })}

          </div>
          
          {provided.placeholder}
        </Container>
      )}
    </Draggable>
  );
}
