import React from 'react';
import { Avatar, IconButton, Stack, Tooltip } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import './ProfileCell.scss'
import { useNavigate } from 'react-router-dom';

const ProfileCell = (props) => {
    const navigate = useNavigate();
    const { cellItem, editActionURL, record, columnKey,actionHandlers } = props;
    const columnId = record?.id ? 'id' : columnKey
    const { imageUrl } = record;
    return (<div className='profile-cell' onClick={() => {
      actionHandlers?.editRowHandler ? actionHandlers?.editRowHandler(record.id) :   navigate(`${editActionURL}/${record[columnId]}`)
    }}>
        <Stack direction="row" spacing={1}>
            {imageUrl && imageUrl?.length > 0 ? <img src={imageUrl[0]} alt={imageUrl[0]} style={{ width: 24, height: 24, borderRadius: '50%', backgroundPosition: "center center" }} /> : <Avatar alt={cellItem} sx={{ width: 24, height: 24, bgcolor: deepOrange[500] }}>{cellItem?.split('')[0]}</Avatar>}
            <Tooltip title={cellItem} placement='top' arrow>
                <span>{cellItem}</span>
            </Tooltip>
        </Stack>
    </div>
    )
}

export default ProfileCell