import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Slide, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from 'axios'

import SyncIcon from '@mui/icons-material/Sync';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import expandCollapseIcon from '../../../assets/images/arrow-blue-circle.svg';
import expandCollapseRightIcon from '../../../assets/images/arrow-blue-circle-right.svg';
import SelectMenu from "../../../components/SelectMenu/SelectMenu";
import PhoneTextField from "../../../components/PhoneTextField/PhoneTextField";
import EmailTextField from "../../../components/EmailTextField/EmailTextField";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactCompanyOwnersList, fetchContactDetails, fetchCustomerDetails, fetchLeadStatusDropdown, getContactNotes, postAddNotes, postCreateContact } from '../../../features/table/tableSlice';
import Notifications from '../../../components/Notifications/Notifications';
import { deepOrange } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import CommentsComponent from './CommentsComponent';
import Notes from './Notes';

import './AddContact.scss';
import TextEditor from '../../../components/TextEditor/TextEditor';

export default function AddContact() {
  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");
  let textValue = ''

  const [displayNameList, setDisplayNameList] = useState([]);
  const [isLeftPanelVisible, setLeftPanelVisibility] = useState(true);
  const [forceSubmitMessage, setForceSubmitMessage] = useState(null)
  const { contactInformation, companyOwnerdropDownData, leadDropdown, notes, noteId } = useSelector(state => state.table)
  let noteIdValue = noteId?.data?.result?.noteId ? noteId?.data?.result?.noteId : localStorage.getItem("noteId");
  const [contactDetails, setContactDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    displayName: '',
    companyName: '',
    phonePrefix: '',
    contactOwner: '',
    contactStage: '',
    contactStatus: '',
  });
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    color: "success",
  });
  const [phonePrefixList, setPhonePrefixList] = useState([]);
  const location = useLocation();
  const [isReadOnly, setIsReadOnly] = useState(location?.pathname?.includes('edit'));
  const [currentNote, setCurrentNote] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleLeftPanel = () => {
    setLeftPanelVisibility(!isLeftPanelVisible);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openModal, setOpenModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  function ConfirmationModal() {
    return <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {/* {forceSubmitMessage} */}
          Email already exist, do you want to save it?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button onClick={addContactBtnClick}>Submit</Button>
      </DialogActions>
    </Dialog>
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const paths = location.pathname.split('/');
  const contactIdByPage = paths[paths.length - 1];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // if (location?.pathname?.includes('edit')) {
    //   setLeftPanelVisibility(false)
    // } else {
    //   setLeftPanelVisibility(true)
    // }
    if (isEmpty(contactDetails)) {
      setLeftPanelVisibility(false)
    } else {
      setLeftPanelVisibility(true)
    }
  }, [contactDetails])

  const addContactBtnClick = () => {
    const url = location?.pathname?.includes('edit') ? `update` : `create`;

    const { displayName, firstName, lastName, email, phone, contactOwner, phonePrefix, companyName, contactStage, contactStatus } = contactDetails
    const requestData = {
      "companyId": company_id,
      "userId": user_id,
      firstName,
      lastName,
      email,
      phone,
      displayName,
      companyName,
      phonePrefix,
      contactOwner,
      contactStage,
      contactStatus,
      "contactId": contactIdByPage,
      "forcesubmit": forceSubmitMessage !== null,

    }

    const response = dispatch(postCreateContact({ endpointURL: `/master/contact/${url}?`, payload: requestData }))
    response.then(function (res) {
      if (res.error?.message?.includes("422")) {
        setForceSubmitMessage(res.payload?.data?.message)
        setOpenModal(true);
      } else {
        if (res.payload?.data?.success) {
          setNotification({
            open: true,
            message: `${res.payload?.data?.message} for ${res.payload?.data.result.contactId}`,
            color: "success",
          })
        }
        dispatch(fetchContactDetails(`/master/contact/details?company_id=${company_id}&user_id=${user_id}&contact_id=${res.payload?.data.result.contactId}`))
        setForceSubmitMessage(null)
        setOpenModal(false);
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went Wrong while updating contact',
          color: "error",
        })
      });
  };

  useEffect(() => {
    dispatch(fetchContactCompanyOwnersList(`/master/employee/dropdown?company_id=${company_id}&user_id=${user_id}`))
    dispatch(fetchLeadStatusDropdown(`/master/option/dropdown?companyId=${company_id}&userId=${user_id}&object=contact`))
    axios.get('https://api.worxstream.io/seeds')?.then((response) => {
      setPhonePrefixList(response?.data?.result?.countrycode?.map((codeList) => ({ label: codeList.code, value: codeList.code })))
    })
  }, [])
  useEffect(() => {
    if (contactIdByPage) {
      dispatch(fetchContactDetails(`/master/contact/details?company_id=${company_id}&user_id=${user_id}&contact_id=${contactIdByPage}`))
      setContactDetails({ ...contactInformation })
    }
  }, [location])

  useEffect(() => {
    setContactDetails({ ...contactInformation })
  }, [contactInformation])

  useEffect(() => {
    let list = [];
    if (contactDetails?.companyName) {
      list.push({
        label: contactDetails.companyName,
        value: contactDetails.companyName,
      })
    }
    if (contactDetails?.firstName && contactDetails?.lastName) {
      list.push({
        label: `${contactDetails.firstName} ${contactDetails.lastName}`,
        value: `${contactDetails.firstName} ${contactDetails.lastName}`,
      })
      list.push({
        label: `${contactDetails.lastName} ${contactDetails.firstName}`,
        value: `${contactDetails.lastName} ${contactDetails.firstName}`,
      })
    }
    if (contactDetails?.firstName && !contactDetails?.lastName) {
      list.push({
        label: contactDetails.firstName,
        value: contactDetails.firstName,
      })
    }
    if (!contactDetails?.firstName && contactDetails?.lastName) {
      list.push({
        label: contactDetails.lastName,
        value: contactDetails.lastName,
      })
    }
    setDisplayNameList(list)
    // setContactDetails(contactInformation)
  }, [contactDetails])

  const onNotesChange = (value) => {
    textValue = value
  };

  const onSave = () => {
    const req = {
      companyId: company_id,
      userId: user_id,
      objectName: "contact",
      objectId: contactIdByPage,
      noteId: noteIdValue,
      note: textValue
    }
    const response = dispatch(postAddNotes({ endpointURL: `/module/note/create?`, payload: req }))
    response.then(function (res) {
      if (res.payload.data.success) {
        setNotification({
          open: true,
          message: `${res.payload.data?.message} for ${res.payload.data.result.noteId}`,
          color: "success",
        })
        if (res.payload.data.result.noteId) {
          noteIdValue = res.payload.data.result.noteId;
          dispatch(getContactNotes(`module/note/list?company_id=${company_id}&user_id=${user_id}&objectName=contact&objectId=${contactIdByPage}`))
          navigate(`/contact/edit/${contactIdByPage}`);
        }
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went Wrong while Adding notes',
          color: "error",
        })
      });
  }

  const onCommentsChange = (value) => {
    const req = {
      companyId: company_id,
      userId: user_id,
      moduleName: "note",
      moduleId: noteIdValue,
      objectId: contactIdByPage,
      comment: value
    }

    const response = dispatch(postAddNotes({ endpointURL: `/module/comment/create?`, payload: req }))
    response.then(function (res) {
      if (res.payload.data.success) {
        setNotification({
          open: true,
          message: `${res.payload.data?.message} for ${res.payload.data.result.commentId}`,
          color: "success",
        })
        if (res.payload.data.result.commentId) {
          dispatch(getContactNotes(`module/note/list?company_id=${company_id}&user_id=${user_id}&objectName=contact&objectId=${contactIdByPage}`))
          navigate(`/contact/edit/${contactIdByPage}`);
        }
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went Wrong while Adding comment',
          color: "error",
        })
      });

  }

  useEffect(() => {
    dispatch(getContactNotes(`module/note/list?company_id=${company_id}&user_id=${user_id}&objectName=contact&objectId=${contactIdByPage}`))
  }, [])

  useEffect(() => {
    setCurrentNote(notes?.find((eachNote) => eachNote.id == noteIdValue))
  }, [notes])

  const onClose = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }

  return (
    <div>
      {openModal && ConfirmationModal()}
      {notification?.open && <Notifications {...notification} onClose={onClose}/>}
      <div className="container-fluid addCustomer">
        <div className="d-flex gap-3 position-relative">
          <IconButton aria-label="Collapse" className={`expandCollapseBtn ${isLeftPanelVisible ? ' ' : 'moveLeft'}`} onClick={toggleLeftPanel}>
            <img src={`${isLeftPanelVisible ? expandCollapseIcon : expandCollapseRightIcon}`} alt='' />
          </IconButton>
          {<div className={`leftPanel ${isLeftPanelVisible ? "visiblePanel " : "hiddenPanel"}`}>
            <div className="white-container">
              <div className="row">
                <div className='col-12 d-flex justify-content-end'>
                  <Button variant='text' className='justify-content-end' startIcon={<ModeEditIcon />} onClick={() => setIsReadOnly(!isReadOnly)}>Edit</Button>
                </div>
              </div>
              <div className="row leftContent">
                <div className='col-12'>
                  <p className='font-medium mb-0'>{location?.pathname?.includes('edit') ? <Stack direction="row" spacing={1} className="justify-content-center">
                    <Avatar alt={contactInformation?.firstName} sx={{ width: 80, height: 80, bgcolor: deepOrange[500] }}>{contactInformation?.firstName?.split('')[0]?.toUpperCase()} {contactInformation?.lastName?.split('')[0]?.toUpperCase()}</Avatar>
                    <div className='col-10 justify-content-start align-items-center justify-content-md-start'><h3>{contactInformation?.firstName} {contactInformation?.lastName}</h3><p>{contactInformation?.contactId}</p>
                    </div>
                  </Stack> : 'Add new Contact'}</p>
                </div>
                <div className="col-12">
                  <TextField InputLabelProps={{ shrink: true }}
                    label="Company Name" name="companyName"
                    value={contactDetails?.companyName}
                    variant="standard"
                    fullWidth
                    onChange={(event) => {
                      setContactDetails({ ...contactDetails, companyName: event.target.value })
                    }}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12 col-lg-6">
                  <TextField InputLabelProps={{ shrink: true }}
                    label="First Name" name="firstName"
                    value={contactDetails?.firstName}
                    variant="standard"
                    fullWidth
                    onChange={(event) => {
                      setContactDetails({ ...contactDetails, firstName: event.target.value })
                    }}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12 col-lg-6">
                  <TextField InputLabelProps={{ shrink: true }} label="Last Name" value={contactDetails?.lastName} name="lastName" variant="standard" fullWidth onChange={(event) => setContactDetails({ ...contactDetails, lastName: event.target.value })}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Display Name" value={contactDetails?.displayName} options={displayNameList}
                    name="displayName"
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, displayName: selectedValue })}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Contact Owner" value={contactDetails?.contactOwner}
                    options={companyOwnerdropDownData?.map((owner) => ({ ...owner, label: owner?.optionShow, value: owner?.employeeId }))}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, contactOwner: selectedValue })}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Lifecycle Stage" value={contactDetails?.contactStage}
                    options={leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId }))}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, contactStage: selectedValue })}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Lead Status" value={contactDetails?.contactStatus}
                    options={leadDropdown && leadDropdown[1]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId }))}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, contactStatus: selectedValue })}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12 ">
                  <EmailTextField label="Email" value={contactDetails?.email} fullWidth onChange={(value) => setContactDetails({ ...contactDetails, email: value })} disabled={isReadOnly} />
                </div>
                {/* <div className="col-12">
                  <SelectMenu selectLabel="Select Country Code" options={phonePrefixList}
                    value={contactDetails?.countryCode}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, countryCode: selectedValue })}
                    disabled={isReadOnly} />
                </div>
                <div className="col-12">
                  <PhoneTextField label="Phone" value={contactDetails?.phone} fullWidth onBlur={(event) => setContactDetails({ ...contactDetails, phone: event.target.value })} disabled={isReadOnly} />
                </div> */}
                <div className="col-12 col-lg-4">
                  <SelectMenu selectLabel="Select Country Code" options={phonePrefixList}
                    value={contactDetails?.phonePrefix}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, phonePrefix: selectedValue })}
                    disabled={isReadOnly}
                  />
                </div>
                <div className="col-12 col-lg-8">
                  <PhoneTextField label="Phone" InputLabelProps={{ shrink: true }} value={contactDetails?.phone} fullWidth onChange={(event) => setContactDetails({ ...contactDetails, phone: event.target.value })}
                    disabled={isReadOnly}
                  />
                </div>
                {!isReadOnly && <div className='col-12'>
                  <div className='d-flex gap-3 pt-3'>
                    <Button variant='outlined' onClick={() => setContactDetails({})}>Reset</Button>
                    <Button variant={'contained'} onClick={addContactBtnClick} >{location?.pathname?.includes('edit') ? 'Update Contact' : 'Add Contact'}</Button>
                  </div>
                </div>}
              </div>
            </div>
          </div>}
          {/* RIGHT CONTAINER */}
          <div className={`rightPanel ${isLeftPanelVisible ? " " : "rp100"}`}>

            <div className="white-container-auto mb-3">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <Stack direction='column' spacing={2}>
                    <h4 title={contactInformation?.displayName}> {contactInformation?.displayName}</h4>
                    {contactInformation?.contactId && <span title='Contact ID' className="m-0">ID: {contactInformation?.contactId}</span>}
                  </Stack>
                </div>
                <div className="col-12 col-lg-3">
                  <Stack direction='column' spacing={2}>
                    {contactInformation?.contactOwnerName && <span title='Contact Owner' className="m-0"> {contactInformation?.contactOwnerName}</span>}
                    {contactInformation?.contactStageName && <span title='Contact Stage' className="m-0">{contactInformation?.contactStageName}</span>}
                  </Stack>
                </div>
                <div className="col-12 col-lg-3">
                  <Stack direction='column' spacing={2}>
                    {contactInformation?.contactStatusName && <span title="Status" className="m-0">{contactInformation?.contactStatusName}</span>}
                    {contactInformation?.email && <span title='Email' className="m-0">{contactInformation?.email}</span>}
                  </Stack>
                </div>
                <div className="col-12 col-lg-3 text-end">
                  <Button variant='text' ><SyncIcon fontSize="small" /> sync to quickbook</Button>
                  <Button style={{ minWidth: '20px', padding: '0px', margin: '0px' }}
                    id="add-button"
                    aria-controls={open ? 'add-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </Button>
                  <Menu
                    id="add-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'add-button',
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon><BusinessCenterOutlinedIcon fontSize="small" /></ListItemIcon>
                      <ListItemText>Add Job</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon><RequestQuoteOutlinedIcon fontSize="small" /></ListItemIcon>
                      <ListItemText>Add Estimate</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon><ReceiptLongOutlinedIcon fontSize="small" /></ListItemIcon>
                      <ListItemText>Add Invoice</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="white-container-auto">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="main tabs">
                  <Tab label="Add Notes" {...a11yProps(0)} />
                  <Tab label="Notes Activity" {...a11yProps(1)} />
                  {/* <Tab label="Notesss" {...a11yProps(2)} /> */}
                  {/* <Tab label="Activity" {...a11yProps(3)} /> */}
                  {/* <Tab label="Estimate" {...a11yProps(3)} />
                    <Tab label="Invoices" {...a11yProps(4)} />
                    <Tab label="Document Templates" {...a11yProps(5)} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0} >
                <div className="pt-3">
                  <TextEditor
                    input={currentNote?.note}
                    onChangeCallback={onNotesChange}
                  />
                  {/* <TextEditorWrapper markup="" onNotesChange={onNotesChange} text={currentNote?.note} /> */}
                  <CommentsComponent onCommentsChange={onCommentsChange} inputData={[]} noteId={noteId?.data?.result?.noteId} />
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1} >
                <Notes contactIdByPage={contactIdByPage} notes={notes} onNotesChange={onNotesChange} onCommentsChange={onCommentsChange} />
                {/* <CustomerInfo /> */}
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2} >
                {/* <Addresses /> */}
                {/* <p>Address</p> */}
                {/* <Notes notes={notes}/> */}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3} >
                {/* <CustomerEstimateList /> */}
                <p>Upcoming Activities here</p>
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={4} >
                  <CustomerInvoiceList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5} >
                  <DocumentTemplates />
                </CustomTabPanel> */}
            </div>
            <div className='row '>
              <div className="col-12 d-flex  justify-content-end align-items-center gap-2 text-end pt-2 ">
                <Button variant="contained" onClick={onSave}>SAVE NOTES</Button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
