import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  Image as ImageIcon,
  CloudUploadOutlined as UploadIcon,
  ViewModuleOutlined as ViewModuleIcon,
  ViewListOutlined as ViewListIcon,
  FolderOutlined as FolderIcon,
  InsertDriveFileOutlined as FileIcon,
  ModeEditOutlineOutlined as EditIcon,
  CheckBox,
  CloudDownload,
  CloudDownloadOutlined,
} from "@mui/icons-material";
import FileUpload from "./FileUpload";
import LoadingComponent from "../../../common/LoadingComponent/LoadingComponent";
import "./FileUploadWrapper.scss";

function FileUploadWrapper({
  onUpload,
  imageLoading,
  imageDetails = [],
  onDelete = () => {},
}) {
  const [view, setView] = useState("grid");
  const [type, setType] = useState("file");
  const [files, setFiles] = useState(
    imageDetails?.map((eachUrl, index) => ({
      ...eachUrl,
      name: eachUrl?.fileName ? eachUrl?.fileName : `File ${index + 1}`,
      url: eachUrl?.url ? eachUrl?.url : eachUrl,
      id: eachUrl?.id ? eachUrl?.id : index + 1,
    }))
  );
  const [folders, setFolders] = useState([
    { id: 1, name: "Folder One" },
    { id: 2, name: "Folder Two" },
  ]);

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  const handleTypeChange = (event, nextType) => {
    setType(nextType);
  };

  const handleDelete = (id, isFile) => {
    if (isFile) {
      setFiles(files.filter((file) => file.id !== id));
      onDelete([id]);
    } else {
      setFolders(folders.filter((folder) => folder.id !== id));
    }
  };

  const fileTypeOptions = [
    { label: "Images", value: 1 },
    { label: "Documents", value: 2 },
  ];

  const handleFileTypeSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const fileSizeOptions = [
    { label: "> 2mb", value: 1 },
    { label: "2mb - 5mb", value: 2 },
    { label: "< 5mb", value: 3 },
  ];

  const handleFileSizeSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const fileUsedInOptions = [
    { label: "Product", value: 1 },
    { label: "Deal", value: 2 },
    { label: "Estimate", value: 3 },
  ];

  const handleUsedInSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const selectFolderOptions = [
    { label: "Product", value: 1 },
    { label: "Deal", value: 2 },
    { label: "Estimate", value: 3 },
  ];

  const handleSelectFolderSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (file) => {
    setSelectedImage(file);
  };
  const handleCloseImageDialog = () => {
    setSelectedImage(null);
  };
  return (
    <Box p={2}>
      {/* <Box display='flex' alignItems='center'>
                <div className="kb-file-upload">
                    <div className="file-upload-box">
                        <input type="file" id="fileupload" className="file-upload-input" multiple name="images[]" />
                        <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                    </div>
                </div>
            </Box> */}
      <FileUpload onUpload={onUpload} imageLoading={imageLoading} />

      {/* <div className='d-flex justify-content-space-between'>
                <div className="d-block d-md-flex align-items-center gap-3 w-100">
                    <ToggleButtonGroup
                        value={type}
                        exclusive
                        onChange={handleTypeChange}
                        aria-label="file or folder"
                        className='mb-2 mb-md-0'
                    >
                        <ToggleButton value="file" aria-label="files" title='Files'>
                            <FileIcon fontSize='small' className='me-2' /> Files
                        </ToggleButton>
                        <ToggleButton value="folder" aria-label="folders" title='Folder'>
                            <FolderIcon fontSize='small' className='me-2' /> Folders
                        </ToggleButton>
                    </ToggleButtonGroup>


                    <AutocompleteSelect options={fileTypeOptions} value={1} label="File Type" onChange={handleFileTypeSelect} />


                    <AutocompleteSelect options={fileSizeOptions} value={1} label="File Size" onChange={handleFileSizeSelect} />


                    <AutocompleteSelect options={fileUsedInOptions} value={1} label="Used in" onChange={handleUsedInSelect} />


                    <AutocompleteSelect options={selectFolderOptions} value={1} label="Select Folder" onChange={handleSelectFolderSelect} />

                </div>
                <div>

                    <ToggleButtonGroup
                        value={view}
                        exclusive
                        onChange={handleViewChange}
                        aria-label="view mode"
                        sx={{ ml: 2 }}
                    >
                        <ToggleButton value="list" aria-label="list view" title='List View'>
                            <ViewListIcon fontSize='small' />
                        </ToggleButton>
                        <ToggleButton value="grid" aria-label="grid view" title='Grid View'>
                            <ViewModuleIcon fontSize='small' />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div> */}

      {imageLoading ? (
        <LoadingComponent />
      ) : (
        <Box mt={2}>
          {view === "list" ? (
            <Box>
              {type === "file"
                ? files.map((file) => (
                    <Paper
                      key={file.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 1,
                        mb: 1,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <FormControlLabel control={<Checkbox />} />
                        <FileIcon sx={{ mr: 2 }} />
                        <Typography variant="body1" sx={{ flex: 1 }}>
                          {file.name}
                        </Typography>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <IconButton edge="end" aria-label="edit">
                          <CloudDownloadOutlined fontSize="small" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(file.id, true)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Paper>
                  ))
                : folders.map((folder) => (
                    <Paper
                      key={folder.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 1,
                        mb: 1,
                      }}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <FormControlLabel control={<Checkbox />} />
                        <FolderIcon sx={{ mr: 2 }} />
                        <Typography variant="body1" sx={{ flex: 1 }}>
                          {folder.name}
                        </Typography>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <IconButton edge="end" aria-label="edit">
                          <CloudDownloadOutlined fontSize="small" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(folder.id, false)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Paper>
                  ))}
            </Box>
          ) : (
            <Grid container spacing={2}>
              {type === "file"
                ? files.map((file, index) => (
                    <Grid item xs={6} sm={3} md={2} key={file.id}>
                      <div className="gridBox">
                        <Stack direction="row" alignItems="center" gap={0}>
                          <FormControlLabel control={<Checkbox />} />
                          <Typography title={file.name} className="imgName">
                            {file.name}
                          </Typography>
                        </Stack>
                        <div className="imgHolder">
                          <div className="file-atc-box" key={index}>
                            {file ? (
                              <div
                                className="file-image"
                                onClick={() => handleImageClick(file)}
                              >
                                {" "}
                                <img src={file?.url} alt={file?.url} />
                              </div>
                            ) : (
                              <div className="file-image">
                                <i className="far fa-file-alt"></i>
                              </div>
                            )}
                            {/*                                     
                                    <div className="file-actions">
                                        <button className="file-action-btn" onClick={() => DeleteFile(id)}><DeleteOutlineOutlinedIcon />Delete</button>
                                        <a href={fileimage} className="file-action-btn" download={filename}><FileDownloadOutlinedIcon />Download</a>
                                    </div> */}
                          </div>
                          {/* <FileIcon sx={{ fontSize: 70, color: '#d7d7d7' }} /> */}
                        </div>
                        <Stack
                          direction="row"
                          gap={1}
                          justifyContent="flex-end"
                          paddingRight={1}
                        >
                          {/* <IconButton edge="end" aria-label="edit" >
                                                <CloudDownloadOutlined fontSize='small' />
                                            </IconButton> */}
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelete(file.id, true)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      </div>
                    </Grid>
                  ))
                : folders.map((folder) => (
                    <Grid item xs={6} sm={3} md={2} key={folder.id}>
                      <div className="gridBox">
                        <Stack direction="row" alignItems="center" gap={0}>
                          <FormControlLabel control={<Checkbox />} />
                          <Typography className="imgName">
                            {folder.name}
                          </Typography>
                        </Stack>
                        <div className="imgHolder">
                          <FolderIcon sx={{ fontSize: 70, color: "#d7d7d7" }} />
                        </div>
                        <Stack
                          direction="row"
                          gap={1}
                          justifyContent="flex-end"
                          paddingRight={1}
                        >
                          <IconButton edge="end" aria-label="edit">
                            <CloudDownloadOutlined fontSize="small" />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelete(folder.id, true)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          )}
        </Box>
      )}

      <Dialog
        open={!!selectedImage}
        onClose={handleCloseImageDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {selectedImage?.name}
          <IconButton
            aria-label="close"
            onClick={handleCloseImageDialog}
            sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedImage && (
            <img
              src={selectedImage.url}
              alt={selectedImage.name}
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default FileUploadWrapper;
