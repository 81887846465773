import * as React from 'react';
import {
  ACTION,
  AMOUNT,
  CHECKBOX,
  STATUS,
  TEXT,
  NUMBER,
  LIST,
  DOCUMENT,
  DATE,
  LINK,
  PROFILE,
  TOGGLE,
  DROPDOWN,
  MARKUP,
  LONG_TEXT,
  TEMP_TEXT,
  EMPTY_VALUE
} from '../../constants/keyConstants';
import { useSelector } from 'react-redux';
import ListCheckbox from '../CustomCheckbox/ListCheckbox';

import StatusCell from '../StatusCell/StatusCell';
import NumberCell from '../NumberCell/NumberCell';
import AmountCell from '../AmountCell/AmountCell';
import ListCell from '../ListCell/ListCell';
import DocumentCell from '../DocumentCell/DocumentCell';
import DateCell from '../DateCell/DateCell';

import ActionCell from '../ActionCell/ActionCell';
import LinkCell from '../LinkCell/LinkCell';
import { Switch, Tooltip } from '@mui/material';
import ProfileCell from '../ProfileCell/ProfileCell';
import DropDownCell from '../DropDownCell/DropDownCell';
import { Markup } from 'interweave';
import { clipLongText } from '../../common/utils';

const TableBodyCell = (props) => {
  const { selectedIds } = useSelector((state) => state.table);
  const { headerConfig,
    onChange,
    record,
    cellItem,
    editAction,
    actionHandlers } = props;

  const { type,
    columnId = 'id',
    cellStyle,
    columnKey,
    customClass } = headerConfig;
  const [showMore, setShowMore] = React.useState(false)

  return (
    <>
      {type === TEXT && <td style={cellStyle}>
        <Tooltip title={cellItem} placement='top' arrow>
          {typeof cellItem === "object" || cellItem === ""|| cellItem === null || cellItem === undefined ? EMPTY_VALUE : cellItem}
        </Tooltip>
      </td>}
      {type === PROFILE && <td style={cellStyle}><ProfileCell {...headerConfig} cellItem={cellItem} record={record} actionHandlers={actionHandlers} /></td>}
      {type === TEMP_TEXT && <td style={cellStyle}>{cellItem?.label?.valueName === "" || cellItem?.label?.valueName === null || cellItem?.label?.valueName === undefined ? EMPTY_VALUE: cellItem?.label?.valueName}</td>}
      {type === LINK && <td style={cellStyle}><LinkCell {...headerConfig} cellItem={cellItem} record={record} /></td>}
      {type === MARKUP && <td style={cellStyle}> <Markup content={cellItem} /></td>}
      {type === LONG_TEXT && (!showMore && cellItem?.length > 80 ? <td style={cellStyle}>{clipLongText(cellItem, 80)}
        <span style={{ color: '#1E90FF', cursor: "pointer" }} onClick={() => setShowMore(!showMore)}>Show More</span>
      </td> :
        <td style={cellStyle}> {cellItem === "" ? <p style={{"margin" : "auto"}}>{EMPTY_VALUE}</p> : <Markup content={cellItem} />}{showMore &&
          <p>
            <span style={{ color: '#1E90FF', cursor: "pointer" }} onClick={() => setShowMore(!showMore)}>Show less</span>
          </p>}
        </td>)}
      {type === NUMBER && <td style={cellStyle}><NumberCell cellItem={cellItem} /></td>}
      {type === STATUS && <td style={cellStyle} >
        <StatusCell {...cellItem}
          enableEdit={editAction?.enableEdit[columnId] === record[columnId]}
          editAction={editAction}
          id={record[columnId]}
        /></td>}
      {type === DROPDOWN && <td style={cellStyle} >
        <DropDownCell title={cellItem}
          columnKey={columnKey}
          enableEdit={editAction?.enableEdit[columnId] === record[columnId] && editAction?.enableEdit["columnKey"] === columnKey}
          editAction={editAction}
          id={record[columnId]}
        /></td>}
      {type === TOGGLE && <td style={cellStyle}> <Switch
        checked={cellItem}
        inputProps={{ "aria-label": "active inactive" }}
      /></td>}
      {type === AMOUNT && <td style={cellStyle}><AmountCell cellItem={cellItem} /></td>}
      {type === LIST && <td style={cellStyle}><ListCell {...cellItem} /></td>}
      {type === DOCUMENT && <td style={cellStyle}><DocumentCell cellItem={cellItem} /></td>}
      {type === DATE && <td style={cellStyle}><DateCell {...cellItem} /></td>}
      {type === CHECKBOX && <td style={cellStyle} className={customClass}>
        <ListCheckbox
          onChange={(event) => onChange(event, record?.id)}
          checked={selectedIds?.includes(record?.id)}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
        /></td>}
      {type === ACTION && (
        <td style={cellStyle}>
          <ActionCell {...headerConfig}
            record={record}
            cellItem={cellItem}
            actionHandlers={actionHandlers}
          /></td>
      )}
    </>
  );
};
export default TableBodyCell;
