import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../api/axiosConfig";


const initialState = {
    pipelines: [],
    selectedPipeline: null,
    PipelineDetails: null,
    stages: [],
    error: null
};

export const fetchPipelineById = createAsyncThunk('pipeline/fetchPipelineById', async (endpointURL) => {

    const response = await getRequest(endpointURL);
    return response.data.result;
}
)

export const fetchPipelineDetails = createAsyncThunk('pipeline/fetchPipelineDetails', async (endpointURL) => {

    const response = await getRequest(endpointURL);
    return response.data.result;
}
);

export const createPipeline = createAsyncThunk('pipeline/createPipeline', async (data) => {
    const response = await postRequest('https://api.worxstream.io/settings/pipeline/create', data);
    return response.data;
});

export const updatePipeline = createAsyncThunk('pipeline/updatePipeline', async (data) => {
    const response = await postRequest('https://api.worxstream.io/settings/pipeline/update', data);
    return response.data;
});
export const fetchStageById = createAsyncThunk('pipeline/fetchStageById', async (endpointURL) => {
    const response = await getRequest(endpointURL);
    return response.data.result;
}
);

export const createStage = createAsyncThunk('pipeline/createStage', async (data) => {
    const response = await postRequest("https://api.worxstream.io/settings/pipeline/stages/create",data);
    return response.data;
}
);

export const deleteStage = createAsyncThunk('pipeline/deleteStage', async (endpointURL) => {
    const response = await postRequest(endpointURL);
    return response.data.result;
}
);

export const updateStage = createAsyncThunk('pipeline/updateStage', async (data) => {
    const response = await postRequest("https://api.worxstream.io/settings/pipeline/stages/update", data);
    return response.data;
}
);

export const deletePipeline = createAsyncThunk('pipeline/deletePipeline', async (endpointURL) => {
    const response = await postRequest(endpointURL);
    return response.data;
}
);

const pipelineSlice = createSlice({
    name: 'pipeline',
    initialState,
    reducers: {
        setSelectPipeline: (state, action) => {
            state.selectedPipeline = action.payload;
        },
        setPipelineDetails: (state, action) => {
            state.PipelineDetails = action.payload;
        },
        setStages: (state, action) => {
            state.stages = action.payload;
        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(fetchPipelineById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.pipelines = action.payload;
        });
        builder.addCase(fetchPipelineDetails.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.PipelineDetails = action.payload;
        });
    }
});

export const { setSelectPipeline, setPipelineDetails,setStages } = pipelineSlice.actions;

export default pipelineSlice.reducer;