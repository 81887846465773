import React, { useEffect, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Button,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  Image as ImageIcon,
  CloudUploadOutlined as UploadIcon,
  ViewModuleOutlined as ViewModuleIcon,
  ViewListOutlined as ViewListIcon,
  FolderOutlined as FolderIcon,
  InsertDriveFileOutlined as FileIcon,
  ModeEditOutlineOutlined as EditIcon,
  CheckBox,
  CloudDownload,
  CloudDownloadOutlined,
  Padding,
} from "@mui/icons-material";

import "./UploadImageDialog.scss";
import FileUpload from "../../features/table/FileUpload/FileUpload";
import AutocompleteSelect from "../AutocompleteSelect/AutocompleteSelect";
import FileUploadWrapper from "../../features/table/FileUpload/FileUploadWrapper";
import { useDispatch, useSelector } from "react-redux";
import { fetchImageList } from "../../features/table/tableSlice";
import { __API_BASEURL__ } from "../../constants/keyConstants";
import axios from "axios";
import Notifications from "../Notifications/Notifications";

const UploadImageDialog = ({ open, onClose }) => {
  const [view, setView] = useState("grid");
  const [type, setType] = useState("file");
  const [imageLoading, setImageLoading] = useState(false);
  const [imageList, setImageList] = useState();
  const [files, setFiles] = useState([
    { id: 1, name: "File One" },
    { id: 2, name: "File Two" },
  ]);
  const [folders, setFolders] = useState([
    { id: 1, name: "Folder One" },
    { id: 2, name: "Folder Two" },
  ]);
  const dispatch = useDispatch();
  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");
  const { imageData } = useSelector((state) => state.table);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "success",
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setImageList(imageData);
  }, [imageData]);

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  const handleTypeChange = (event, nextType) => {
    setType(nextType);
  };

  const handleDelete = (id, isFile) => {
    if (isFile) {
      setFiles(files.filter((file) => file.id !== id));
    } else {
      setFolders(folders.filter((folder) => folder.id !== id));
    }
  };

  const fileTypeOptions = [
    { label: "Images", value: 1 },
    { label: "Documents", value: 2 },
  ];

  const handleFileTypeSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const fileSizeOptions = [
    { label: "> 2mb", value: 1 },
    { label: "2mb - 5mb", value: 2 },
    { label: "< 5mb", value: 3 },
  ];

  const handleFileSizeSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const fileUsedInOptions = [
    { label: "Product", value: 1 },
    { label: "Deal", value: 2 },
    { label: "Estimate", value: 3 },
  ];

  const handleUsedInSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  const selectFolderOptions = [
    { label: "Product", value: 1 },
    { label: "Deal", value: 2 },
    { label: "Estimate", value: 3 },
  ];

  const handleSelectFolderSelect = (event, value) => {
    console.log("Selected value:", value);
  };

  useEffect(() => {
    dispatch(
      fetchImageList({
        endpointURL: `/master/library/list?company_id=${company_id}&user_id=${user_id}&search=${searchTerm}`,
      })
    ).then((res) => setImageList(res?.payload?.list));
  }, [searchTerm]);

  const onUpload = (formDataList) => {
    setImageLoading(true);
    const formData = new FormData();

    formDataList.forEach((file) => {
      formData.append("images[]", file); // Append each file to the FormData
    });
    formData.append("companyId", company_id);
    formData.append("userId", user_id);
    formData.append("objectName", "library");

    axios
      .post(`${__API_BASEURL__}/module/uploads/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (res) {
        if (res) {
          setTimeout(() => setImageLoading(false), 3000);
        }
      })
      .catch(function (error) {
        setImageLoading(false);
        setNotification({
          open: true,
          message: `Something went Wrong while adding files`,
          color: "error",
        });
      });
  };

  const onDelete = (idList = []) => {
    setNotification({
      open: true,
      message: "Do you really want to delete?",
      color: "warning",
      confirmationHandler: () => deleteHandler(idList),
    });
  };
  const deleteHandler = (ids) => {
    setImageLoading(true);
    const deleteRequest = {
      companyId: company_id,
      userId: user_id,
      fileIds: ids,
    };

    axios
      .post(`${__API_BASEURL__}/master/library/delete`, deleteRequest, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (res) {
        if (res.data.success) {
          setImageLoading(false);
          setNotification({
            open: true,
            message: res?.data?.message,
            color: "success",
          });
        }
      })
      .catch(function (error) {
        setImageLoading(false);
        setNotification({
          open: true,
          message: `Something went Wrong while deleting files`,
          color: "error",
        });
      });
  };

  const onCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      className="uploadImageDialog"
    >
      {notification?.open && (
        <Notifications {...notification} onClose={onCloseNotification} />
      )}
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        X Drive
      </DialogTitle>
      <div className="imageSearchBox">
        <TextField
          variant="outlined"
          placeholder="Search for images"
          size="small"
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </div>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 12,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {imageList && imageList?.length > 0 && (
          <FileUploadWrapper
            imageDetails={imageList}
            onUpload={onUpload}
            imageLoading={imageLoading}
            onDelete={onDelete}
          />
        )}
      </DialogContent>
      <DialogActions>
          <Button autoFocus onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button autoFocus onClick={onClose} variant="contained">
            Use selected Image(s)
          </Button>
        </DialogActions>
      {/* <Box p={2}>
        <Box display='flex' alignItems='center'>
          {/* <FileUpload  />
          <div className="kb-file-upload">
              <div className="file-upload-box">
                  <input type="file" id="fileupload" className="file-upload-input" multiple name="images[]" />
                  <span>Drag and drop or <span className="file-link">Choose your files</span></span>
              </div>
          </div>
        </Box>
        
          <div className='d-flex justify-content-space-between'>
              <div className="d-block d-md-flex align-items-center gap-3 w-100">
                <ToggleButtonGroup
                  value={type}
                  exclusive
                  onChange={handleTypeChange}
                  aria-label="file or folder"
                  className='mb-2 mb-md-0'
                  >
                    <ToggleButton value="file" aria-label="files" title='Files'>
                      <FileIcon fontSize='small' className='me-2'/> Files
                    </ToggleButton>
                    <ToggleButton value="folder" aria-label="folders" title='Folder'>
                      <FolderIcon fontSize='small' className='me-2'/> Folders
                    </ToggleButton>
                  </ToggleButtonGroup>
              
              
                <AutocompleteSelect options={fileTypeOptions} value={1} label="File Type" onChange={handleFileTypeSelect} />
              
              
                <AutocompleteSelect options={fileSizeOptions} value={1} label="File Size" onChange={handleFileSizeSelect} />
              
              
                <AutocompleteSelect options={fileUsedInOptions} value={1} label="Used in" onChange={handleUsedInSelect} />
              
              
                <AutocompleteSelect options={selectFolderOptions} value={1} label="Select Folder" onChange={handleSelectFolderSelect} />
              
              </div>
              <div>
            
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                  aria-label="view mode"
                  sx={{ ml: 2 }}
                >
                  <ToggleButton value="list" aria-label="list view" title='List View'>
                    <ViewListIcon fontSize='small' />
                  </ToggleButton>
                  <ToggleButton value="grid" aria-label="grid view" title='Grid View'>
                    <ViewModuleIcon fontSize='small'  />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
          </div>
        
        <Box mt={2}>
          {view === 'list' ? (
            <Box>
              {type === 'file' ? (
                files.map(file => (
                  <Paper key={file.id} sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', p: 1, mb: 1 }}>
                     <div className='d-flex align-items-center'>
                    <FormControlLabel control={<Checkbox />} />
                    <FileIcon sx={{ mr: 2 }} />
                    <Typography variant="body1" sx={{ flex: 1 }}>
                      {file.name}
                    </Typography>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                      <IconButton edge="end" aria-label="edit" >
                            <CloudDownloadOutlined fontSize='small' />
                          </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.id, true)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Paper>
                ))
              ) : (
                folders.map(folder => (
                  <Paper key={folder.id} sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', p: 1, mb: 1 }}>
                    <div className='d-flex align-items-center gap-2'>
                      <FormControlLabel control={<Checkbox />} />
                      <FolderIcon sx={{ mr: 2 }} />
                      <Typography variant="body1" sx={{ flex: 1 }}>
                        {folder.name}
                      </Typography>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                      <IconButton edge="end" aria-label="edit" >
                            <CloudDownloadOutlined fontSize='small' />
                          </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(folder.id, false)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Paper>
                ))
              )}
            </Box>
          ) : (
            <Grid container spacing={2}>
              {type === 'file' ? (
                files.map(file => (
                  <Grid item xs={6} sm={4} md={3} key={file.id}>
                    <div className='gridBox'>
                      <Stack direction='row' alignItems='center' gap={0}>
                        <FormControlLabel control={<Checkbox />} />
                        <Typography className='imgName'>{file.name}</Typography>
                      </Stack>
                      <div className='imgHolder'>
                        <FileIcon sx={{ fontSize: 70, color: '#d7d7d7' }} />
                      </div>
                      <Stack direction='row' gap={1} justifyContent='flex-end' paddingRight={1} >
                        <IconButton edge="end" aria-label="edit" >
                          <CloudDownloadOutlined fontSize='small' />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.id, true)}>
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </Stack>
                    </div>
                  </Grid>
                ))
              ) : (
                folders.map(folder => (
                  <Grid item xs={6} sm={4} md={3} key={folder.id}>
                    <div className='gridBox'>
                      <Stack direction='row' alignItems='center' gap={0}>
                        <FormControlLabel control={<Checkbox />} />
                        <Typography className='imgName'>{folder.name}</Typography>
                      </Stack>
                      <div className='imgHolder'>
                        <FolderIcon sx={{ fontSize: 70, color: '#d7d7d7' }} />
                      </div>
                      <Stack direction='row' gap={1} justifyContent='flex-end' paddingRight={1} >
                        <IconButton edge="end" aria-label="edit" >
                          <CloudDownloadOutlined fontSize='small' />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(folder.id, true)}>
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </Stack>
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Box>
      </Box> */}
    </Dialog>
  );
};

export default UploadImageDialog;
